// @ts-nocheck
var customCssUrl = "https://embedded-component-sandbox.payabli.com/customForm.css";
export var payabliConfigCard = {
  type: "methodEmbedded",
  rootContainer: "pay-component-card",
  defaultOpen: "card",
  token: "",
  // customCssUrl: customCssUrl,
  entryPoint: "",
  card: {
    enabled: true,
    amex: false,
    discover: true,
    visa: true,
    mastercard: true,
    inputs: {
      cardHolderName: {
        label: "NAME ON CARD",
        placeholder: "",
        floating: false,
        value: "",
        size: 12,
        row: 0,
        order: 0
      },
      cardNumber: {
        label: "CARD NUMBER",
        placeholder: "1234 1234 1234 1234",
        floating: false,
        size: 6,
        row: 1,
        order: 0
      },
      cardExpirationDate: {
        label: "EXPIRATION DATE",
        placeholder: "MM/YY",
        floating: false,
        size: 6,
        row: 1,
        order: 1
      },
      cardCvv: {
        label: "CVV/CVC",
        placeholder: "CVV/CVC",
        floating: false,
        size: 6,
        row: 2,
        order: 0
      },
      cardZipcode: {
        label: "ZIP/POSTAL CODE",
        placeholder: "Zip/Postal Code",
        floating: false,
        size: 6,
        row: 2,
        order: 1,
        country: ["us", "ca"]
      }
    }
  },
  ach: {
    enabled: false
  },
  customerData: {
    customerNumber: "",
    firstName: "",
    lastName: "",
    billingEmail: ""
  },
  functionCallBackSuccess: function (response) {
    paycomponentCard.removeListener();
    paycomponentCard.payabliExec("pay");
  },
  functionCallBackReady: function (data) {},
  functionCallBackError: function (errors) {
    alert("Error!");
    console.log(errors);
  }
};
export var payabliConfigACH = {
  type: "methodEmbedded",
  entryPoint: "",
  rootContainer: "pay-component-card",
  defaultOpen: "ach",
  token: "",
  // customCssUrl: customCssUrl,
  customerData: {
    customerNumber: "",
    firstName: "",
    lastName: "",
    billingEmail: ""
  },
  card: {
    enabled: false
  },
  ach: {
    enabled: true,
    checking: true,
    savings: true,
    inputs: {
      achAccountHolderName: {
        label: "ACCOUNTHOLDER NAME",
        floating: false,
        size: 6,
        row: 0,
        order: 0
      },
      achAccountType: {
        label: "ACCOUNT TYPE",
        floating: false,
        size: 6,
        row: 0,
        order: 1
      },
      achRouting: {
        label: "ROUTING NUMBER",
        floating: false,
        size: 6,
        row: 1,
        order: 0,
        confirm: true
      },
      achAccount: {
        label: "ACCOUNT NUMBER",
        floating: false,
        size: 6,
        row: 1,
        order: 1,
        confirm: true
      }
    }
  },
  

  functionCallBackSuccess: function (response) {
    alert("ach");
  },
  functionCallBackError: function (errors) {
    console.log(errors);
  },
  functionCallBackReady: function (data) {}
};