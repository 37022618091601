import React from 'react';

interface LineProps {
  width?: string; // Width of the line
  height?: string; // Thickness of the line
  color?: string; // Color of the line
  className?: string; // Additional Tailwind classes
}

const Line: React.FC<LineProps> = ({
  width = '100%',
  height = '1px',
  color = '#E5E7EB',
  className = '',
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: color,
      }}
      className={`rounded ${className}`}
    />
  );
};

export default Line;
