import React, { useEffect, useState } from 'react';
import BillCard from './BillCard';
// import ActionButton from './ActionButton';
// import MagicStar from '../../assets/SVGs/MagicStar';
import Line from '../shared/Line';
import { fetchBillsByStatus } from '../../utils/utils';
import LoadingOverlay from '../insurance/LoadingOverlay';
import { useNavigate } from "react-router-dom";
interface BillData {
  id: number;
  amount: number;
  dueDate: string;
  status: string;
  providerId: string;
  providerName?: string;
  providerAddress?: string;
  userName?: string;
  userEmail?: string;
  userPhoneNumber?: string;
  statementDate?: string;
  invoiceLink?: string;
  comment?: string;
  jsonData?: string;
  patientName?: string;
  patientAddress?: string;
  userSaved?: boolean;
  firstName?: string;
  lastName?: string;
  paymentDate?: string;
  isPaid?: boolean;
}

const MyBills: React.FC = () => {
  const [bills, setBills] = useState<BillData[]>([]);
  const [paidBills, setPaidBills] = useState<BillData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBills = async () => {
      try {
        setLoading(true);
        const response = await fetchBillsByStatus(); // Replace with actual API endpoint
        setBills([ ...response?.receiptsByProvider]);
      } catch (error) {
        console.error('Error fetching bills:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchPaidBills = async () => {
      try {
        setLoading(true);
        const response = await fetchBillsByStatus("Paid"); // Replace with actual API endpoint
        setPaidBills([ ...response?.receiptsByProvider]);
      } catch (error) {
        console.error('Error fetching bills:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBills();
    fetchPaidBills();
  }, []);

  const handleCardClick = (bill: BillData) => {
    navigate("/statement", { state: { bill } });
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      {loading && <LoadingOverlay />}
      <h2 className="text-2xl font-bold text-gray-800 mb-6">My Bills</h2>

      {/* Bills List */}
      <div className="flex flex-wrap gap-4">
        {bills.length > 0 ? (
          bills.map((bill, index) => (
            <BillCard
              key={bill.id || index}
              amount={bill.amount}
              dueDate={bill.dueDate}
              status={bill.status}
              onClick={() => handleCardClick(bill)}
            />
          ))
        ) : (
          <div className="text-gray-500">No bills available.</div>
        )}
      </div>
      <div className="my-4">
        <Line height="2px" />
      </div>
      {
        paidBills.length > 0 && (
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Payment History</h2>
        )
      }
      <div className="flex flex-wrap gap-4">
      {
        paidBills?.map((bill, index) => (
          <BillCard
            key={bill.id || index}
            amount={bill.amount}
            dueDate={bill.dueDate}
            status={bill.status}
            onClick={()=>{}}
            bill={bill}
          />
        ))
      }
      </div>
      {/*
      
      <div className="flex flex-wrap justify-between gap-4 mt-8">
         <ActionButton
          icon={<MagicStar />}
          label="Upload Bill"
          onClick={() => console.log('Upload Bill clicked')}
        /> 
        <ActionButton
          icon={<MagicStar />}
          label="Payment History"
          onClick={() => console.log('Payment History clicked')}
        /> 
      </div>
      */}
    </div>
  );
};

export default MyBills;
