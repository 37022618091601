import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/login/login';
import { isOvum, localStorageUpdateAfterLogin, loginApi, setCookie } from './utils/utils';
import Home from './components/home/home';
import Chat from './components/chat';
import Setting from './components/settings';
import { useAuthContext } from './context/AuthContext';
import { useDispatch } from 'react-redux';
import { openSideBar } from './store/sidebarSlice';
import Signup from './components/signup/Signup';
import { SESSION_TIMEOUT_DAY } from './../src/utils/constants';
import Insurance from './components/insurance';
import Sandravoice from './components/sandravoice';
import { getFeaturesForCustomer, Features } from './utils/ClientBasedFeature'
import LinkPayment from './components/linkpayment/LinkPayment';
import OvumLandingPage from './components/home/OvumLandingPage';
import MyBills from './components/bills';
import Statement from './components/bills/statement/Statement';

type AppRouterType = {};

const AppRoutes: React.FC<AppRouterType> = () => {
  const { setIsAuthenticated, isAuthenticated } = useAuthContext();
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading ]= useState(false);
  const [features, setFeatures] = useState<Features | undefined>(undefined);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const login = async (username: string, password: string) => {
    if (username !== '' && password !== '') {
      try{
        setLoading(true);
        const response = await loginApi(username, password);
        localStorageUpdateAfterLogin(response);
        dispatch(openSideBar());
        setIsAuthenticated(true);
        if(!isOvum()) {
          window.location.href = "/";
        }
      } catch (error: any) {
        setAlertMessage({
          type: 'error',
          text:  error?.response?.data?.message || 'Invalid username or password',
        } as any);
      } finally {
        setLoading(false);
      }
    } else {
      alert('username or password should not empty');
    }
  };

  useEffect(() => {
    const isAuth = localStorage.getItem('isAuthenticated') || isAuthenticated;
    const authTimestamp = localStorage.getItem('authTimestamp');
    const currentTime = new Date().getTime();

    if (
      isAuth === 'true' &&
      authTimestamp &&
      currentTime - parseInt(authTimestamp) < SESSION_TIMEOUT_DAY
    ) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('authTimestamp');
      // dispatch(hideSideBar())
      // navigate('/login');
    }
    const customerFeatures = getFeaturesForCustomer();
    setFeatures(customerFeatures);
  }, [isAuthenticated, setIsAuthenticated]);

  if(isOvum() && ["/asksandra","/insurance"].includes(window.location.pathname) && !isAuthenticated) {
    navigate('/')
  }

  if(isOvum()) {
    return (
    <Routes>
      <Route path="/" element={<OvumLandingPage />} />
      <Route path="/asksandra" element={<Chat />} />
      <Route path="/insurance" element={<Insurance />} />
      <Route path="/payments" element={<LinkPayment />} />
    </Routes>
    );
  }
  
  if(["/","/signup","/login", "/payments"].includes(window.location.pathname) && !isAuthenticated) {
    return (
    <Routes>
      <Route path="/" element={<Home isAuthenticated={isAuthenticated} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login login={login} alertMessage={alertMessage} setAlertMessage={setAlertMessage} loading={loading} />} />
      <Route path="/payments" element={<LinkPayment />} />
    </Routes>
    );
  }

  if(!isAuthenticated) {
    return <Login login={login} alertMessage={alertMessage} setAlertMessage={setAlertMessage} loading={loading}/>
  }

  return (
    <Routes>
      <Route path="/" element={<Home isAuthenticated={isAuthenticated} />} />
      <Route path="/login" element={<Login login={login} alertMessage={alertMessage} setAlertMessage={setAlertMessage} loading={loading} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/about" element={<>Hello About us</>} />
      <Route path="/contact" element={<>Hello contact us</>} />
      <Route path="/asksandra" element={<Chat />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/insurance" element={<Insurance />} />
      <Route path="/ovumlanding" element={<OvumLandingPage />} />
      <Route path="/statement" element={<Statement />} />
      {features && features.sandraVoice && (<Route path="/sandravoice" element={<Sandravoice />} /> )}
      {features && features.sandraVoice && (<Route path="/sophia" element={<Sandravoice />} /> )}
      { features && features.showBillPaymentOption && <Route path="/bills" element={<MyBills />} /> }
    </Routes>
  );
};

export default AppRoutes;
