import React from 'react';
import ProgressBar from '../shared/ProgressBar';

interface BillCardProps {
  amount: number;
  dueDate: string;
  status: string;
  onClick: () => void;
  bill?: any;
}

const BillCard: React.FC<BillCardProps> = ({
  amount,
  dueDate,
  status,
  onClick,
  bill,
}) => {
  return (
    <div className="bg-white p-4 rounded-2xl shadow-md flex flex-col md:w-[32%] w-full hover:cursor-pointer min-w-[350px]" onClick={onClick}>
      <div className="flex w-full justify-between items-center">
        {
          bill?.isPaid ? 
            (<div className="text-teal-40 text-base">Payment Date {bill.paymentDate}</div>) 
            : (<div className="text-teal-40 text-base">Payment Due {dueDate}</div>)
        }
        
        <span
          className={`text-sm font-medium py-1 px-2 rounded-full bg-teal-40 text-white`}
        >
          {status}
        </span>
      </div>

      <div className="text-2xl font-bold">${amount.toFixed(2)}</div>
      {/* <div className="mt-2">
        <ProgressBar progress={20} />
      </div> */}
      {
        !bill?.isPaid && (
          <div className="mt-4 w-full text-gray-500 text-sm flex justify-between items-center">
              <span>Pay now</span> 
              {/* or schedule a payment */}
              <span>→</span>
            </div>
        )
      }
    </div>
  );
};

export default BillCard;
