import * as React from "react";
import { SVG } from "../../utils/types";

const LinkPaymentSideImage: React.FC<SVG> = ({
    width = 830,
    height = 564,
    color = '#9EA7B8',
    otherProps,
  }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 830 864"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g filter="url(#filter0_i_3837_3343)">
      <path
        d="M227.309 377.03C227.309 250.54 329.849 148 456.339 148C582.829 148 685.369 250.54 685.369 377.03V787H227.309V377.03Z"
        fill="#F7EDFF"
      />
    </g>
    <g clipPath="url(#clip0_3837_3343)">
      <path
        d="M249.239 224.081C206.089 255.295 174.918 301.668 178.318 355.629L178.175 352.622C177.566 360.161 178.55 367.743 181.062 374.878L181.165 375.41L181.165 375.062C188.439 399.274 204.883 419.698 226.998 431.989C242.535 440.087 259.975 443.837 277.471 442.843C294.968 441.849 311.87 436.148 326.387 426.344C343.344 414.561 356.779 397.092 376.153 389.953C397.964 381.915 422.56 388.747 445.334 384.083C488.075 375.369 513.572 324.905 503.988 282.399C494.403 239.893 457.437 207.491 416.375 192.743C360.097 172.553 297.592 189.122 249.239 224.081Z"
        fill="#F5F5F5"
        fillOpacity={0.1}
      />
      <path
        d="M604.5 579C666.632 579 717 528.632 717 466.5C717 404.368 666.632 354 604.5 354C542.368 354 492 404.368 492 466.5C492 528.632 542.368 579 604.5 579Z"
        fill="#F5F5F5"
        fillOpacity={0.1}
      />
      <path
        d="M288.089 697.124H183.32V711.518H288.089V697.124Z"
        fill="#1B0134"
      />
      <path
        d="M288.279 711.691H183.147V696.934H288.279V711.691ZM183.51 711.328H287.916V697.297H183.51V711.328Z"
        fill="#1B0134"
      />
      <path
        d="M193.031 706.714L208.117 781.122H263.914L278.498 711.518L193.031 706.714Z"
        fill="#1B0134"
      />
      <path
        d="M193.031 774.089L201.084 785.148H268.908L278.498 774.089H193.031Z"
        fill="#53277B"
      />
      <path
        d="M231.773 708.891C231.566 708.891 231.307 674.418 231.186 631.909C231.065 589.4 231.186 554.927 231.341 554.927C231.497 554.927 231.808 589.383 231.929 631.909C232.05 674.435 231.981 708.891 231.773 708.891Z"
        fill="#1B0134"
      />
      <path
        d="M255.205 622.63C255.132 622.909 255.034 623.181 254.911 623.442L253.978 625.671L253.304 627.278C253.079 627.883 252.855 628.539 252.596 629.231C252.336 629.922 252.077 630.682 251.801 631.477C251.524 632.272 251.3 633.205 251.023 634.034C249.779 638.243 248.798 642.526 248.086 646.856C247.273 652.176 246.73 657.534 246.461 662.909C246.076 672.709 246.353 682.523 247.291 692.285C247.636 696.018 247.965 699.042 248.224 701.133C248.328 702.1 248.414 702.861 248.483 703.535C248.506 703.816 248.506 704.1 248.483 704.381C248.374 704.117 248.31 703.837 248.293 703.552C248.172 702.93 248.051 702.135 247.878 701.167C247.55 699.094 247.118 696.07 246.72 692.337C245.627 682.586 245.274 672.766 245.666 662.961C245.942 657.559 246.519 652.177 247.394 646.839C248.13 642.48 249.169 638.179 250.505 633.965C250.799 633.067 251.023 632.237 251.334 631.408C251.645 630.578 251.922 629.87 252.198 629.179C252.475 628.488 252.717 627.831 252.959 627.226C253.2 626.621 253.477 626.12 253.702 625.636C254.134 624.755 254.479 624.029 254.773 623.459C254.872 623.162 255.018 622.882 255.205 622.63Z"
        fill="#1B0134"
      />
      <path
        d="M252.44 594.843C252.349 595.23 252.233 595.611 252.095 595.984C251.835 596.779 251.472 597.85 251.006 599.181C250.764 599.889 250.505 600.667 250.211 601.496C249.917 602.326 249.675 603.224 249.364 604.278C248.759 606.283 248.051 608.546 247.412 611.087C247.083 612.365 246.72 613.696 246.358 615.078C245.995 616.461 245.718 617.93 245.373 619.433C244.647 622.44 244.128 625.688 243.437 629.092C242.297 636.004 241.277 643.504 240.569 651.556C239.86 659.609 239.618 667.108 239.515 674.124C239.411 681.14 239.515 687.343 239.878 692.579C239.964 695.206 240.137 697.573 240.275 699.664C240.413 701.755 240.517 703.535 240.655 705.003C240.793 706.472 240.845 707.544 240.914 708.373C240.945 708.77 240.945 709.169 240.914 709.565C240.815 709.18 240.751 708.787 240.724 708.39C240.621 707.561 240.5 706.438 240.327 705.038C240.154 703.638 239.981 701.772 239.826 699.698C239.67 697.625 239.428 695.24 239.307 692.614C238.962 687.43 238.806 681.088 238.789 674.124C238.772 667.16 239.135 659.505 239.791 651.505C240.448 643.504 241.519 635.953 242.729 629.041C243.42 625.585 243.973 622.37 244.733 619.364C245.09 617.855 245.436 616.403 245.77 615.009C246.15 613.627 246.513 612.296 246.876 611.017C247.533 608.477 248.276 606.214 248.932 604.226C249.261 603.241 249.554 602.308 249.831 601.444C250.107 600.58 250.436 599.855 250.695 599.163C251.213 597.833 251.628 596.779 251.922 596.001C252.055 595.599 252.229 595.211 252.44 594.843Z"
        fill="#1B0134"
      />
      <path
        d="M225.155 714.991C225.138 714.859 225.138 714.726 225.155 714.594C225.155 714.283 225.121 713.885 225.051 713.401C225.051 712.296 224.879 710.775 224.758 708.84C224.498 704.813 224.136 699.094 223.704 692.043C222.788 677.874 221.474 658.296 219.09 636.747C217.88 625.982 216.463 615.752 214.994 606.473C214.217 601.859 213.508 597.453 212.696 593.357C211.884 589.262 211.158 585.443 210.433 581.97C209.707 578.497 208.981 575.369 208.359 572.621C207.737 569.874 207.167 567.437 206.631 565.571C206.095 563.705 205.802 562.201 205.542 561.13C205.421 560.664 205.335 560.283 205.266 559.972C205.197 559.661 205.266 559.575 205.266 559.575C205.338 559.692 205.391 559.821 205.421 559.955L205.75 561.096L206.821 565.467C207.357 567.385 207.979 569.736 208.549 572.483C209.119 575.231 210.001 578.341 210.726 581.814C211.452 585.288 212.316 589.089 213.076 593.202C213.837 597.314 214.649 601.704 215.444 606.335C216.947 615.614 218.381 625.844 219.608 636.626C221.993 658.175 223.22 677.787 223.997 691.922C224.395 699.025 224.654 704.762 224.81 708.736C224.896 710.671 224.948 712.192 224.982 713.315C224.982 713.799 224.982 714.179 224.982 714.49C225.052 714.653 225.109 714.82 225.155 714.991Z"
        fill="#1B0134"
      />
      <path
        d="M231.013 571.498C230.546 566.625 225.622 562.858 220.645 562.858C218.189 562.973 215.791 563.642 213.63 564.815C211.47 565.988 209.603 567.635 208.169 569.632C205.345 573.662 203.305 578.187 202.155 582.972C198.588 594.436 197.337 606.496 198.475 618.448C199.874 630.337 204.696 642.035 213.232 650.433C214.614 651.798 216.411 653.163 218.312 652.749C219.345 652.426 220.271 651.828 220.991 651.021C229.343 643.38 235.965 634.041 240.412 623.63C244.858 613.22 247.027 601.979 246.772 590.662C246.634 586.134 246.047 581.434 243.541 577.667C241.035 573.9 235.195 569.908 230.944 571.412"
        fill="#53277B"
      />
      <path
        d="M231.341 554.927L169.133 518.172C168.449 517.871 167.905 517.321 167.613 516.634C167.371 515.718 168.062 514.906 168.736 514.146C173.018 509.702 178.207 506.233 183.95 503.976C189.693 501.719 195.855 500.726 202.017 501.065C208.177 501.505 214.138 503.438 219.382 506.699C224.627 509.96 228.999 514.449 232.119 519.779C238.132 530.527 238.322 544.731 231.428 554.927"
        fill="#53277B"
      />
      <path
        d="M178.032 506.439L178.845 506.335C179.38 506.335 180.175 506.214 181.195 506.232C184.089 506.184 186.982 506.41 189.835 506.905C199.335 508.515 208.179 512.805 215.324 519.27C222.469 525.736 227.618 534.108 230.166 543.401C230.936 546.188 231.451 549.039 231.704 551.92C231.734 552.496 231.734 553.072 231.704 553.648C231.658 554.133 231.499 554.601 231.238 555.013C231.398 553.971 231.398 552.91 231.238 551.868C230.892 549.021 230.314 546.206 229.51 543.453C226.895 534.307 221.772 526.077 214.72 519.694C207.668 513.311 198.97 509.03 189.61 507.337C186.759 506.806 183.87 506.5 180.97 506.422C179.173 506.473 178.032 506.508 178.032 506.439Z"
        fill="#1B0134"
      />
      <path
        d="M222.909 529.56C221.919 530.157 220.864 530.639 219.764 530.994C217.777 531.771 215.012 532.722 211.901 533.759C208.791 534.795 205.957 535.487 203.883 536.04C202.762 536.392 201.601 536.606 200.427 536.679C201.475 536.185 202.569 535.797 203.693 535.521L211.625 533.05L219.539 530.493C220.625 530.061 221.755 529.748 222.909 529.56Z"
        fill="#1B0134"
      />
      <path
        d="M213.094 503.259C213.655 505.519 213.905 507.844 213.837 510.171C214.009 512.482 213.881 514.805 213.457 517.083C213.156 514.792 213.035 512.481 213.094 510.171C212.909 507.871 212.909 505.56 213.094 503.259Z"
        fill="#1B0134"
      />
      <path
        d="M168.511 517.481C169.968 516.812 171.484 516.28 173.039 515.891C175.855 515.01 179.795 513.904 184.201 512.85C188.608 511.796 192.6 510.984 195.52 510.465C197.081 510.108 198.672 509.894 200.272 509.826C198.752 510.323 197.199 510.71 195.624 510.984C192.738 511.623 188.712 512.522 184.374 513.576C180.037 514.63 176.045 515.649 173.177 516.392C171.65 516.866 170.091 517.23 168.511 517.481Z"
        fill="#1B0134"
      />
      <path
        d="M205.093 560.905C199.736 556.845 194.086 556.05 187.364 556.309C180.634 556.756 174.047 558.455 167.941 561.32C157.435 565.917 147.568 572.708 141.295 582.298C135.023 591.888 132.828 604.607 137.528 615.13C137.893 616.222 138.651 617.139 139.654 617.705C140.801 618.017 142.022 617.926 143.11 617.446C155.05 613.54 167.077 609.618 178.067 603.501C189.057 597.384 199.045 588.916 204.938 577.823C206.46 575.209 207.425 572.309 207.771 569.304C207.933 567.8 207.771 566.279 207.297 564.842C206.823 563.406 206.048 562.087 205.024 560.975"
        fill="#53277B"
      />
      <path
        d="M205.179 559.644C204.9 559.781 204.612 559.896 204.315 559.99L201.81 560.854L200.082 561.458L197.974 562.288C196.453 562.893 194.708 563.515 192.79 564.396L189.852 565.744C188.833 566.21 187.83 566.781 186.742 567.334C184.582 568.388 182.387 569.753 180.054 571.135C170.036 577.345 161.124 585.182 153.685 594.325C151.957 596.45 150.402 598.455 149.071 600.477C148.38 601.462 147.689 602.395 147.084 603.345C146.479 604.296 145.944 605.211 145.356 606.075C144.233 607.803 143.403 609.531 142.609 610.879C142.211 611.605 141.831 612.262 141.52 612.884L140.69 614.612C140.207 615.597 139.826 616.34 139.515 616.979C139.395 617.265 139.244 617.537 139.066 617.791C139.134 617.492 139.239 617.202 139.377 616.927L140.431 614.491C140.662 613.949 140.921 613.373 141.209 612.763C141.503 612.141 141.866 611.467 142.246 610.741C143.006 609.29 143.801 607.613 144.907 605.868L146.635 603.086C147.222 602.118 147.914 601.185 148.587 600.166C149.918 598.127 151.525 596.105 153.184 593.928C156.901 589.318 160.968 585.003 165.349 581.019C169.827 577.152 174.599 573.639 179.622 570.513C181.99 569.148 184.202 567.8 186.396 566.746C187.485 566.21 188.504 565.64 189.541 565.191L192.496 563.878C194.379 563.014 196.159 562.443 197.68 561.856C198.458 561.579 199.166 561.303 199.823 561.096L201.672 560.543L204.212 559.817C204.528 559.73 204.852 559.672 205.179 559.644Z"
        fill="#1B0134"
      />
      <path
        d="M163.31 582.315C159.045 583.947 154.696 585.349 150.281 586.514C145.935 587.905 141.516 589.059 137.044 589.97C141.31 588.342 145.66 586.941 150.074 585.771C154.417 584.371 158.836 583.217 163.31 582.315Z"
        fill="#1B0134"
      />
      <path
        d="M162.774 611.069C162.564 609.726 162.471 608.368 162.498 607.009C162.498 604.503 162.498 601.03 162.498 597.193C162.498 593.357 162.826 589.901 163.034 587.396C163.071 586.035 163.239 584.681 163.535 583.352C163.651 584.709 163.651 586.073 163.535 587.43C163.535 590.178 163.345 593.513 163.241 597.211C163.137 600.909 163.051 604.261 162.982 607.009C163.051 608.366 162.981 609.726 162.774 611.069Z"
        fill="#1B0134"
      />
      <path
        d="M187.415 598.507C187.208 598.507 186.551 591.474 185.947 582.765C185.342 574.056 185.031 566.988 185.238 566.971C185.446 566.953 186.102 574.004 186.69 582.713C187.277 591.422 187.623 598.507 187.415 598.507Z"
        fill="#1B0134"
      />
      <path
        d="M156.709 566.28C158.097 566.116 159.496 566.076 160.891 566.159C163.465 566.159 167.025 566.245 170.948 566.383C174.87 566.522 178.43 566.677 181.005 566.815C182.403 566.825 183.797 566.964 185.169 567.23C183.782 567.393 182.382 567.434 180.987 567.351C178.413 567.351 174.853 567.247 170.93 567.126C167.008 567.005 163.448 566.833 160.873 566.677C159.476 566.678 158.081 566.544 156.709 566.28Z"
        fill="#1B0134"
      />
      <path
        d="M215.893 652.075C215.812 651.806 215.776 651.526 215.789 651.245C215.789 650.623 215.755 649.828 215.686 648.861C215.686 646.77 215.565 643.763 215.686 640.031C215.807 636.298 216.014 631.874 216.446 626.967C216.878 622.059 217.638 616.685 218.64 611.087C219.643 605.488 220.921 600.2 222.183 595.431C223.444 590.662 224.827 586.428 226.071 582.903C227.315 579.378 228.404 576.561 229.216 574.643C229.613 573.745 229.924 573.001 230.166 572.431C230.27 572.167 230.398 571.913 230.546 571.671C230.501 571.951 230.419 572.224 230.304 572.483C230.097 573.071 229.821 573.814 229.492 574.747C228.749 576.682 227.764 579.533 226.572 583.076C225.38 586.618 224.118 590.852 222.857 595.604C221.595 600.356 220.403 605.626 219.401 611.156C218.399 616.685 217.673 622.094 217.154 626.984C216.636 631.874 216.36 636.281 216.221 639.996C216.083 643.711 216.031 646.718 216.031 648.792C216.031 649.777 216.031 650.52 216.031 651.193C216.011 651.491 215.965 651.786 215.893 652.075Z"
        fill="#1B0134"
      />
      <path
        d="M219.176 611.294C218.444 612.288 217.61 613.203 216.688 614.024C215.081 615.631 212.783 617.791 210.173 620.072C207.564 622.353 205.128 624.358 203.261 625.74C202.335 626.553 201.321 627.26 200.237 627.848C201.053 626.928 201.956 626.089 202.933 625.343L209.638 619.519L216.273 613.644C217.161 612.767 218.134 611.98 219.176 611.294Z"
        fill="#1B0134"
      />
      <path
        d="M238.893 627.174C237.901 626.037 237.016 624.811 236.249 623.511L230.08 614.525L223.807 605.643C222.867 604.457 222.023 603.197 221.284 601.876C222.364 602.939 223.347 604.096 224.222 605.332C225.95 607.527 228.266 610.62 230.702 614.128C233.139 617.636 235.229 620.867 236.681 623.252C237.535 624.49 238.275 625.803 238.893 627.174Z"
        fill="#1B0134"
      />
      <path
        d="M228.559 577.235C228.663 577.408 222.114 581.814 213.923 587.085C205.732 592.355 199.01 596.468 198.907 596.295C198.803 596.122 205.335 591.716 213.526 586.445C221.716 581.175 228.438 577.062 228.559 577.235Z"
        fill="#1B0134"
      />
      <path
        d="M247.031 597.159C243.554 594.303 240.242 591.251 237.113 588.018C233.818 584.952 230.686 581.717 227.73 578.324C231.205 581.189 234.516 584.246 237.648 587.482C240.945 590.539 244.077 593.769 247.031 597.159Z"
        fill="#1B0134"
      />
      <path
        d="M251.749 596.761L257.382 596.295C261.372 595.978 265.246 594.799 268.735 592.839C270.441 591.842 272.207 590.954 274.023 590.178C280.935 587.396 288.728 587.897 296.21 587.517C308.463 586.899 320.467 583.832 331.514 578.497C332.158 578.199 332.695 577.71 333.051 577.097C334.209 574.989 332.066 573.122 330.218 571.913C320.748 566.072 311.106 560.162 300.358 557.259C289.61 554.356 277.427 554.858 268.251 561.165C257.503 568.578 253.581 583.784 251.749 596.71"
        fill="#53277B"
      />
      <path
        d="M333.086 576.388C332.757 576.266 332.439 576.115 332.136 575.939L329.474 574.539C327.159 573.33 323.824 571.533 319.625 569.528C314.732 567.113 309.631 565.147 304.384 563.653C297.958 561.793 291.225 561.235 284.581 562.011C277.946 562.756 271.68 565.45 266.575 569.753C262.582 573.456 259.389 577.937 257.192 582.92C255.222 587.119 254.013 590.713 253.114 593.167L252.112 596.001C252.014 596.335 251.887 596.658 251.732 596.969C251.771 596.621 251.852 596.278 251.974 595.949C252.198 595.224 252.492 594.221 252.838 593.064C253.932 589.535 255.23 586.073 256.726 582.696C258.883 577.605 262.079 573.02 266.109 569.234C271.335 564.804 277.769 562.042 284.581 561.303C291.305 560.526 298.117 561.114 304.608 563.031C309.88 564.585 314.998 566.615 319.901 569.096C324.083 571.187 327.384 573.053 329.647 574.28L332.239 575.801C332.54 575.969 332.824 576.165 333.086 576.388Z"
        fill="#1B0134"
      />
      <path
        d="M319.59 583.646C319.469 583.819 312.125 579.05 303.157 573.019C294.189 566.988 287.035 561.942 287.156 561.77C287.277 561.597 294.621 566.366 303.589 572.397C312.557 578.427 319.694 583.473 319.59 583.646Z"
        fill="#1B0134"
      />
      <path
        d="M311.745 561.389C310.016 561.971 308.212 562.297 306.388 562.357C304.575 562.607 302.733 562.577 300.928 562.271C302.709 561.958 304.511 561.779 306.319 561.735C308.117 561.484 309.931 561.369 311.745 561.389Z"
        fill="#1B0134"
      />
      <path
        d="M279.553 557.19C278.95 558.566 278.134 559.837 277.133 560.957C276.292 562.25 275.267 563.414 274.092 564.413C274.683 563.036 275.494 561.763 276.494 560.646C277.345 559.355 278.374 558.192 279.553 557.19Z"
        fill="#1B0134"
      />
      <path
        d="M286.136 587.931C284.89 587.422 283.702 586.778 282.594 586.013C280.486 584.735 277.6 582.834 274.541 580.622C271.483 578.41 268.77 576.285 266.817 574.747C265.723 573.969 264.705 573.089 263.776 572.12C264.966 572.744 266.094 573.479 267.145 574.315L274.973 580.017C278.032 582.229 280.849 584.182 282.888 585.581C284.033 586.275 285.119 587.061 286.136 587.931Z"
        fill="#1B0134"
      />
      <path
        d="M259.957 620.66C254.583 619.243 248.189 624.444 247.083 629.887C245.978 635.33 248.017 640.877 250.401 645.889C255.514 657.109 263.099 667.028 272.589 674.902C282.157 682.771 294.105 687.174 306.492 687.395C304.228 676.854 307.097 665.864 305.835 655.168C304.721 645.741 300.441 636.97 293.696 630.291C286.95 623.611 278.139 619.418 268.701 618.396C265.245 618.033 261.322 617.497 259.957 620.66Z"
        fill="#53277B"
      />
      <path
        d="M306.492 687.395C306.226 687.303 305.971 687.181 305.732 687.032C305.023 686.646 304.352 686.195 303.727 685.684C301.472 683.73 299.663 681.314 298.422 678.6C297.576 676.872 296.694 675.144 295.692 673.139C294.69 671.135 293.809 669.009 292.806 666.763C291.756 664.425 290.568 662.151 289.247 659.954C287.795 657.621 286.166 655.402 284.374 653.319C280.71 648.999 276.77 645.266 273.349 641.707C270.253 638.602 267.442 635.224 264.951 631.615C263.268 629.176 261.887 626.542 260.838 623.77C260.558 623.017 260.316 622.25 260.113 621.472C260.015 621.212 259.963 620.937 259.957 620.66C259.957 620.66 260.32 621.748 261.098 623.666C262.246 626.365 263.683 628.932 265.383 631.321C267.925 634.852 270.757 638.164 273.85 641.223C277.306 644.679 281.229 648.481 284.944 652.835C286.762 654.951 288.415 657.204 289.886 659.574C291.206 661.804 292.389 664.113 293.428 666.486L296.245 672.88C297.144 674.867 298.077 676.699 298.906 678.358C300.068 681.046 301.774 683.464 303.917 685.46C305.473 686.808 306.544 687.309 306.492 687.395Z"
        fill="#1B0134"
      />
      <path
        d="M278.758 678.634C278.791 677.415 278.959 676.204 279.259 675.023C279.673 672.811 280.365 669.839 281.298 666.486C282.231 663.134 283.216 660.231 284.011 658.14C284.373 656.977 284.866 655.858 285.479 654.805C285.262 655.984 284.938 657.141 284.512 658.261C283.855 660.404 282.939 663.359 282.006 666.659C281.073 669.96 280.278 672.932 279.777 675.109C279.552 676.314 279.21 677.495 278.758 678.634Z"
        fill="#1B0134"
      />
      <path
        d="M305.473 652.783C304.193 652.61 302.932 652.321 301.706 651.919C299.39 651.314 296.193 650.485 292.651 649.69C289.108 648.895 285.86 648.273 283.51 647.824C282.227 647.653 280.962 647.376 279.725 646.994C281.023 646.956 282.321 647.06 283.596 647.305C285.981 647.617 289.247 648.152 292.806 649.033C296.366 649.915 299.563 650.761 301.844 651.487C303.096 651.79 304.312 652.224 305.473 652.783Z"
        fill="#1B0134"
      />
      <path
        d="M258.868 660.3C258.919 658.891 259.11 657.49 259.439 656.118C259.905 653.561 260.666 650.036 261.668 646.2C262.67 642.363 263.741 638.907 264.571 636.454C264.966 635.098 265.481 633.779 266.109 632.514C265.887 633.906 265.546 635.276 265.089 636.609C264.363 639.098 263.361 642.553 262.394 646.39C261.426 650.226 260.562 653.699 259.957 656.239C259.701 657.619 259.337 658.977 258.868 660.3Z"
        fill="#1B0134"
      />
      <path
        d="M293.221 630.267C293.221 630.475 286.516 629.922 278.274 629.058C270.031 628.194 263.379 627.33 263.396 627.105C268.401 627.254 273.396 627.657 278.36 628.315C283.344 628.711 288.304 629.363 293.221 630.267Z"
        fill="#1B0134"
      />
      <path
        d="M288.262 711.449C288.262 711.656 264.744 711.829 235.713 711.829C206.683 711.829 183.147 711.656 183.147 711.449C183.147 711.241 206.683 711.086 235.713 711.086C264.744 711.086 288.262 711.241 288.262 711.449Z"
        fill="#53277B"
      />
      <path
        d="M271.898 741.723C271.898 741.931 256.035 742.086 236.491 742.086C216.947 742.086 201.067 741.931 201.067 741.723C201.067 741.516 216.93 741.343 236.491 741.343C256.052 741.343 271.898 741.533 271.898 741.723Z"
        fill="#53277B"
      />
      <path
        d="M271.276 746.061C271.276 746.268 255.724 746.441 236.491 746.441C217.258 746.441 201.689 746.268 201.689 746.061C201.689 745.853 217.241 745.698 236.491 745.698C255.741 745.698 271.276 745.853 271.276 746.061Z"
        fill="#53277B"
      />
      <path
        d="M272.485 738.164C272.485 738.371 256.086 738.544 235.851 738.544C215.616 738.544 199.218 738.371 199.218 738.164C199.218 737.956 215.616 737.801 235.851 737.801C256.086 737.801 272.485 737.956 272.485 738.164Z"
        fill="#53277B"
      />
      <g filter="url(#filter1_d_3837_3343)">
        <path
          d="M381.3 161.5C371.665 167.643 360.448 170.844 349.021 170.71C345.408 170.488 341.786 170.448 338.169 170.589C334.527 170.739 331.039 172.094 328.251 174.442C326.881 175.639 325.904 177.22 325.448 178.981C324.992 180.741 325.077 182.598 325.693 184.309C327.732 188.975 334.333 190.824 335.335 195.818C336.061 199.412 333.383 202.833 330.359 204.924C327.335 207.015 323.81 208.38 321.269 211.041C319.736 212.634 318.653 214.605 318.131 216.754C317.609 218.902 317.666 221.15 318.297 223.269C318.928 225.388 320.109 227.302 321.721 228.815C323.334 230.328 325.318 231.386 327.473 231.881C330.048 232.486 333.21 232.503 334.61 234.749C335.352 236.456 335.486 238.365 334.99 240.158C334.311 244.009 334.823 247.976 336.459 251.528C337.301 253.287 338.613 254.779 340.251 255.837C341.889 256.895 343.788 257.479 345.738 257.524C347.259 257.524 348.745 257.006 350.265 257.041C351.024 256.994 351.779 257.179 352.43 257.571C353.081 257.963 353.598 258.543 353.911 259.235C354.072 260.271 354.025 261.328 353.773 262.346C353.22 267.443 358.3 271.435 363.225 272.886C373.058 275.824 383.961 272.99 392.325 266.942C400.688 260.894 406.408 251.943 410.296 242.439C414.184 232.935 416.275 222.844 418.348 212.787"
          fill="#1B0134"
        />
        <path
          d="M491.132 415.671C490.03 412.949 488.377 410.483 486.276 408.431C473.264 394.607 453.548 387.954 434.592 389.043C415.636 390.131 397.474 398.374 382.752 410.314C374.889 416.691 364.279 428.977 361.515 438.723C373.058 441.505 390.303 437.634 401.259 432.882C412.214 428.13 422.928 422.393 434.73 421.183C448.416 419.784 461.86 424.639 475.338 427.387C478.033 428.044 480.816 428.265 483.581 428.044C484.962 427.92 486.301 427.505 487.511 426.826C488.72 426.146 489.772 425.219 490.596 424.104C491.321 422.831 491.746 421.411 491.839 419.949C491.932 418.488 491.69 417.025 491.132 415.671Z"
          fill="#502C3B"
        />
        <g opacity={0.3}>
          <path
            d="M491.132 415.671C490.03 412.949 488.377 410.483 486.276 408.431C473.264 394.607 453.548 387.954 434.592 389.043C415.636 390.131 397.474 398.374 382.752 410.314C374.889 416.691 364.279 428.977 361.515 438.723C373.058 441.505 390.303 437.634 401.259 432.882C412.214 428.13 422.928 422.393 434.73 421.183C448.416 419.784 461.86 424.639 475.338 427.387C478.033 428.044 480.816 428.265 483.581 428.044C484.962 427.92 486.301 427.505 487.511 426.826C488.72 426.146 489.772 425.219 490.596 424.104C491.321 422.831 491.746 421.411 491.839 419.949C491.932 418.488 491.69 417.025 491.132 415.671Z"
            fill="black"
          />
        </g>
        <path
          d="M481.714 580.522L514.667 559.181C514.667 559.181 547.499 585.499 553.91 582.147L514.788 516.845L462.067 547.949L481.714 580.522Z"
          fill="#502C3B"
        />
        <path
          d="M499.72 540.657C499.531 539.913 499.625 539.125 499.982 538.446C500.339 537.767 500.935 537.243 501.655 536.977C502.393 536.762 503.185 536.839 503.868 537.193C504.55 537.547 505.069 538.15 505.319 538.877C505.512 539.665 505.407 540.496 505.024 541.21C504.641 541.925 504.008 542.473 503.245 542.748C502.454 542.892 501.638 542.725 500.967 542.28C500.297 541.835 499.825 541.149 499.651 540.364"
          fill="#E0E0E0"
        />
        <g opacity={0.6}>
          <path
            d="M514.788 516.845L509.604 520.198L549.106 581.542C549.106 581.542 552.113 583.702 553.962 582.147L514.788 516.845Z"
            fill="white"
          />
        </g>
        <path
          d="M514.944 560.426C515.255 560.236 514.443 558.542 514.529 556.365C514.615 554.188 515.462 552.546 515.168 552.321C514.875 552.097 513.302 553.686 513.25 556.33C513.198 558.974 514.702 560.685 514.944 560.426Z"
          fill="#1B0134"
        />
        <path
          d="M521.89 565.592C522.236 565.489 521.89 563.864 522.651 561.877C523.411 559.89 524.603 558.819 524.379 558.507C524.154 558.196 522.253 559.078 521.424 561.445C520.594 563.812 521.579 565.765 521.89 565.592Z"
          fill="#1B0134"
        />
        <path
          d="M532.431 564.573C532.31 564.245 530.461 564.763 529.2 566.629C527.938 568.495 528.146 570.396 528.491 570.379C528.837 570.362 529.217 568.876 530.219 567.355C531.222 565.834 532.587 564.918 532.431 564.573Z"
          fill="#1B0134"
        />
        <path
          d="M506.891 564.469C507.116 564.158 505.785 562.949 504.835 561.013C503.884 559.078 503.608 557.384 503.228 557.384C502.848 557.384 502.537 559.406 503.694 561.618C504.852 563.83 506.736 564.78 506.891 564.469Z"
          fill="#1B0134"
        />
        <path
          opacity={0.3}
          d="M486.916 533.244L501.897 567.424L496.869 570.984L479.589 537.651L486.916 533.244Z"
          fill="black"
        />
        <path
          d="M467.285 399.134C465.938 399.134 421.614 398.149 397.682 413.96L378.674 424.639L379.175 443.82C379.175 443.82 375.114 450.594 376.013 454.655C376.911 458.716 375.892 475.875 375.892 475.875C375.892 475.875 368.789 476.324 367.891 488.524C367.891 488.524 358.041 492.584 353.721 499.807C349.401 507.03 305.13 588.298 302.434 601.397C299.739 614.495 297.907 637.512 307.843 645.184C317.779 652.856 340.018 650.143 340.018 650.143C340.018 650.143 343.181 659.63 362.603 660.079C382.026 660.529 411.799 637.961 411.799 637.961L501.206 577.014L479.986 531.724L448.761 550.818L482.164 511.99C482.164 511.99 497.525 485.811 492.998 458.266C489.663 437.824 467.285 399.134 467.285 399.134Z"
          fill="#1B0134"
        />
        <path
          d="M474.941 413.165H473.887H470.88C468.253 413.165 464.486 413.165 459.821 413.165C455.155 413.165 449.591 413.39 443.474 414.047C436.834 414.682 430.286 416.049 423.947 418.125C410.452 422.687 399.6 429.927 391.876 435.094L382.786 441.384L380.315 443.112C380.042 443.331 379.74 443.511 379.417 443.647C379.417 443.647 379.659 443.388 380.194 442.956L382.579 441.124C384.67 439.535 387.763 437.271 391.513 434.575C399.168 429.27 410.02 421.892 423.654 417.295C430.056 415.196 436.676 413.834 443.387 413.235C448.85 412.729 454.335 412.504 459.821 412.561C464.486 412.561 468.271 412.733 470.88 412.872L473.887 413.044C474.242 413.037 474.596 413.078 474.941 413.165Z"
          fill="#53277B"
        />
        <path
          d="M383.719 427.266C383.012 427.976 382.526 428.877 382.32 429.858C382.231 430.363 382.27 430.882 382.433 431.367C382.596 431.853 382.878 432.291 383.253 432.64C383.71 433.061 384.273 433.35 384.881 433.478C385.489 433.605 386.121 433.567 386.709 433.366C387.273 433.107 387.757 432.7 388.109 432.189C388.462 431.678 388.671 431.082 388.713 430.463C388.748 429.95 388.654 429.436 388.439 428.968C388.225 428.5 387.898 428.094 387.486 427.784C386.659 427.208 385.678 426.895 384.67 426.886C384.67 426.886 384.929 426.661 385.517 426.627C386.346 426.579 387.167 426.81 387.849 427.283C388.393 427.621 388.839 428.096 389.142 428.66C389.445 429.224 389.595 429.857 389.577 430.497C389.592 431.305 389.36 432.098 388.912 432.771C388.464 433.443 387.822 433.963 387.072 434.262C386.321 434.56 385.497 434.623 384.71 434.441C383.923 434.259 383.209 433.842 382.665 433.245C382.222 432.799 381.898 432.249 381.723 431.645C381.547 431.041 381.527 430.403 381.663 429.789C381.845 428.977 382.281 428.245 382.907 427.698C383.391 427.283 383.702 427.214 383.719 427.266Z"
          fill="#53277B"
        />
        <path
          d="M399.634 429.599C399.548 429.685 398.926 429.219 398.045 428.32C396.759 427.198 395.859 425.699 395.471 424.037C395.083 422.375 395.228 420.633 395.885 419.058C396.282 417.848 396.645 417.157 396.749 417.192C396.852 417.226 396.749 418.004 396.507 419.213C396.142 420.725 396.089 422.295 396.351 423.827C396.799 425.318 397.539 426.704 398.528 427.905C399.254 428.89 399.721 429.512 399.634 429.599Z"
          fill="#53277B"
        />
        <path
          d="M403.384 427.3C403.384 427.387 402.693 427.007 401.881 426.16C400.837 425.015 400.064 423.651 399.617 422.168C398.923 420.11 398.893 417.886 399.531 415.809C399.842 415.809 399.531 418.678 400.481 421.909C401.228 423.817 402.203 425.627 403.384 427.3Z"
          fill="#53277B"
        />
        <path
          d="M451.232 413.165C451.094 413.165 451.042 412.353 450.921 411.075C450.789 409.446 450.422 407.846 449.833 406.323C449.185 404.829 448.272 403.464 447.137 402.296C446.273 401.363 445.72 400.776 445.806 400.672C445.893 400.568 446.584 400.983 447.534 401.83C448.872 402.951 449.926 404.372 450.61 405.977C451.252 407.592 451.558 409.32 451.509 411.057C451.585 411.772 451.49 412.495 451.232 413.165Z"
          fill="#53277B"
        />
        <path
          d="M457.038 413.165C456.9 413.165 456.883 412.353 456.9 411.092C456.999 409.491 456.865 407.885 456.503 406.323C455.923 404.822 455.169 403.396 454.256 402.072C453.582 401 453.219 400.344 453.323 400.223C453.427 400.102 453.98 400.724 454.775 401.709C455.886 402.998 456.764 404.472 457.367 406.063C457.783 407.726 457.836 409.459 457.522 411.144C457.349 412.319 457.159 413.165 457.038 413.165Z"
          fill="#53277B"
        />
        <path
          d="M380.609 609.432C380.793 608.726 381.066 608.047 381.421 607.41C382.026 606.114 382.959 604.23 384.186 601.828C386.64 597.007 390.251 590.078 394.778 581.542C403.833 564.469 416.482 540.951 430.565 515.048L440.933 496.127C444.085 490.269 446.527 484.056 448.208 477.62C451.063 466.68 451.651 455.272 449.936 444.097C448.805 436.567 446.66 429.226 443.56 422.272C442.471 419.801 441.521 417.935 440.864 416.673L440.104 415.239C440.015 415.079 439.94 414.911 439.879 414.738C439.998 414.883 440.102 415.039 440.19 415.204L441.02 416.587C441.728 417.814 442.748 419.663 443.888 422.116C451.961 439.546 453.727 459.24 448.882 477.827C447.203 484.341 444.749 490.629 441.573 496.559C438.255 502.659 434.816 508.983 431.308 515.463L395.314 581.836L384.462 601.984L381.542 607.479L380.816 608.931C380.762 609.103 380.692 609.271 380.609 609.432Z"
          fill="#53277B"
        />
        <path
          d="M480.332 550.956C480.028 551.211 479.704 551.443 479.364 551.647L476.496 553.375L465.903 560.045C456.969 565.575 444.666 573.265 431.205 581.974C417.744 590.683 405.682 598.684 397.042 604.524L386.674 611.436L383.823 613.268C383.502 613.499 383.161 613.702 382.803 613.873C383.079 613.591 383.387 613.342 383.719 613.13L386.467 611.142L396.662 604.006C405.302 598.044 417.398 589.888 430.79 581.162C444.182 572.435 456.624 564.832 465.644 559.458C470.154 556.762 473.835 554.62 476.375 553.168L479.33 551.44C479.65 551.251 479.985 551.09 480.332 550.956Z"
          fill="#53277B"
        />
        <path
          d="M494.311 581.645C493.977 581.099 493.699 580.52 493.482 579.917L491.322 575.01C489.456 570.88 486.795 565.212 483.805 558.974C480.816 552.736 478.224 547.258 476.185 542.973L473.921 538.1C473.61 537.519 473.367 536.904 473.195 536.268C473.615 536.796 473.963 537.377 474.232 537.996C474.854 539.119 475.718 540.726 476.755 542.748C478.829 546.774 481.645 552.39 484.617 558.646C487.59 564.901 490.182 570.604 491.91 574.82C492.774 576.911 493.413 578.639 493.81 579.848C494.039 580.428 494.207 581.031 494.311 581.645Z"
          fill="#53277B"
        />
        <path
          d="M380.609 609.432C380.453 609.242 395.729 595.729 414.754 579.244C433.779 562.758 449.314 549.557 449.487 549.747C449.66 549.937 434.367 563.501 415.342 579.917C396.316 596.333 380.782 609.622 380.609 609.432Z"
          fill="#53277B"
        />
        <path
          d="M361.67 641.106C363.067 639.903 364.304 638.527 365.351 637.011C367.2 634.142 369.463 629.926 372.021 625.347C374.578 620.767 376.738 616.499 378.328 613.441C379.101 611.744 380.002 610.109 381.024 608.55C380.472 610.339 379.76 612.073 378.898 613.734C377.447 616.879 375.339 621.165 372.816 625.83C370.293 630.496 367.908 634.574 365.904 637.425C365.1 638.557 364.133 639.565 363.035 640.415C362.631 640.734 362.167 640.969 361.67 641.106Z"
          fill="#53277B"
        />
        <path
          d="M377.861 645.996C377.723 645.996 378.708 644.044 379.797 640.812C381.156 636.572 382.093 632.208 382.596 627.783C383.212 623.381 383.212 618.914 382.596 614.512C382.392 613.196 382.002 611.916 381.438 610.71C381.144 610.316 380.89 609.893 380.678 609.449C380.678 609.449 381.127 609.743 381.732 610.538C382.451 611.724 382.954 613.029 383.218 614.391C384.054 618.842 384.171 623.399 383.564 627.887C383.065 632.38 382.021 636.796 380.453 641.037C379.97 642.316 379.392 643.558 378.725 644.752C378.207 645.581 377.913 646.031 377.861 645.996Z"
          fill="#53277B"
        />
        <path
          d="M359.147 619.748C360.252 619.246 361.412 618.874 362.603 618.642C365.328 617.904 367.991 616.956 370.569 615.808C376.565 613.009 380.488 609.155 380.695 609.432C379.943 610.385 379.045 611.213 378.034 611.885C375.846 613.766 373.41 615.336 370.794 616.551C368.171 617.735 365.423 618.621 362.603 619.195C361.482 619.535 360.319 619.721 359.147 619.748Z"
          fill="#53277B"
        />
        <path
          d="M382.009 494.191C381.944 494.322 381.862 494.444 381.767 494.554L381.006 495.574C380.333 496.472 379.365 497.803 378.207 499.565C374.951 504.589 372.207 509.927 370.016 515.498C366.844 523.788 364.207 532.272 362.119 540.899C359.545 550.593 356.935 561.393 352.598 572.176C348.261 582.959 342.628 592.532 339.068 601.898C335.722 610.111 333.901 618.865 333.694 627.731C333.693 631.122 334.075 634.501 334.834 637.805C335.443 640.298 336.27 642.733 337.305 645.08C337.965 646.567 338.71 648.015 339.534 649.418C339.776 649.867 339.984 650.23 340.157 650.524C340.232 650.65 340.295 650.783 340.347 650.921C340.24 650.818 340.147 650.702 340.07 650.575L339.379 649.521C338.489 648.145 337.687 646.713 336.977 645.236C335.878 642.886 334.999 640.439 334.35 637.926C333.531 634.583 333.096 631.156 333.054 627.714C333.145 618.759 334.903 609.898 338.238 601.587C341.781 592.134 347.259 582.475 351.7 571.813C356.141 561.151 358.612 550.351 361.238 540.709C363.379 532.06 366.092 523.563 369.36 515.273C371.61 509.691 374.455 504.367 377.844 499.393C379.054 497.665 380.073 496.334 380.799 495.487L381.629 494.52C381.888 494.381 381.991 494.174 382.009 494.191Z"
          fill="#53277B"
        />
        <path
          d="M384.946 439.656C384.694 440.378 384.394 441.082 384.048 441.764C383.222 443.664 382.641 445.661 382.32 447.708C381.934 450.747 382.039 453.827 382.631 456.832C383.166 460.288 384.601 463.744 385.568 467.788C386.058 469.74 386.227 471.76 386.069 473.766C385.92 475.613 385.625 477.444 385.188 479.244C384.457 482.268 383.563 485.25 382.51 488.178C381.628 490.684 380.782 492.671 380.298 494.053C380.059 494.783 379.741 495.484 379.347 496.144C379.487 495.389 379.695 494.649 379.969 493.932C380.488 492.36 381.127 490.355 381.905 487.971C382.682 485.586 383.633 482.458 384.376 479.054C385.366 475.446 385.473 471.653 384.687 467.995C383.754 464.142 382.302 460.495 381.818 456.97C381.24 453.879 381.193 450.712 381.68 447.605C382.049 445.521 382.731 443.505 383.702 441.626C383.952 441.113 384.235 440.616 384.549 440.14C384.756 439.811 384.946 439.638 384.946 439.656Z"
          fill="#53277B"
        />
        <path
          d="M387.02 491.651C388.941 489.508 390.421 487.006 391.374 484.29C392.335 481.335 392.791 478.238 392.722 475.132C392.586 471.307 392.112 467.502 391.305 463.761C390.466 459.99 389.826 456.178 389.387 452.339C389.112 449.174 389.175 445.987 389.577 442.835C389.826 440.707 390.337 438.617 391.098 436.614C391.301 436.073 391.544 435.548 391.824 435.042C392.031 434.696 392.135 434.524 392.169 434.524C392.204 434.524 391.875 435.318 391.426 436.735C390.804 438.749 390.393 440.823 390.199 442.922C389.905 446.025 389.905 449.149 390.199 452.253C390.528 455.709 391.392 459.562 392.169 463.589C392.991 467.384 393.449 471.249 393.534 475.132C393.569 478.323 393.03 481.496 391.945 484.497C391.219 486.531 390.13 488.417 388.731 490.061C388.343 490.498 387.928 490.907 387.486 491.288C387.21 491.548 387.037 491.669 387.02 491.651Z"
          fill="#53277B"
        />
        <path
          d="M430.842 478.035C430.159 478.228 429.458 478.355 428.751 478.415C427.403 478.588 425.45 478.899 423.066 479.313C416.834 480.394 410.698 481.966 404.714 484.013C398.737 486.087 392.931 488.623 387.348 491.599C385.205 492.757 383.495 493.708 382.32 494.399C381.733 494.8 381.101 495.131 380.436 495.384C380.963 494.895 381.543 494.466 382.164 494.105C383.287 493.327 384.963 492.273 387.072 491.046C398.149 484.698 410.326 480.5 422.962 478.674C425.381 478.346 427.351 478.138 428.716 478.069C429.421 477.97 430.135 477.959 430.842 478.035Z"
          fill="#53277B"
        />
        <path
          d="M433.659 459.182C433.659 459.355 431.101 459.32 427.075 459.942C424.735 460.316 422.425 460.859 420.163 461.567C414.583 463.319 409.362 466.058 404.749 469.654C402.866 471.124 401.093 472.729 399.444 474.458C396.645 477.412 395.228 479.538 395.09 479.434C395.313 478.876 395.616 478.352 395.988 477.879C396.886 476.521 397.886 475.233 398.978 474.026C400.56 472.206 402.294 470.523 404.162 468.997C406.49 467.157 408.976 465.526 411.592 464.124C414.242 462.767 417.006 461.645 419.852 460.772C422.174 460.087 424.55 459.601 426.954 459.32C428.575 459.105 430.209 459.012 431.844 459.044C432.452 459.019 433.061 459.065 433.659 459.182Z"
          fill="#53277B"
        />
        <path
          d="M437.512 498.719C436.897 499.038 436.247 499.287 435.577 499.462C434.298 499.876 432.483 500.499 430.22 501.293C424.369 503.385 418.641 505.802 413.061 508.534C407.498 511.286 402.097 514.355 396.887 517.727C394.882 519.023 393.275 520.094 392.169 520.854C391.634 521.279 391.055 521.644 390.441 521.943C390.91 521.428 391.437 520.969 392.014 520.578C393.05 519.731 394.606 518.573 396.576 517.122C401.712 513.562 407.107 510.391 412.715 507.635C418.298 504.878 424.073 502.527 429.995 500.602C432.276 499.894 434.142 499.358 435.438 499.047C436.113 498.852 436.81 498.741 437.512 498.719Z"
          fill="#53277B"
        />
        <path
          d="M492.082 453.497C491.486 453.374 490.907 453.183 490.354 452.927C489.283 452.512 487.71 451.925 485.671 451.199C480.053 449.359 474.264 448.091 468.391 447.414L468.823 447.017C469.031 454.465 468.979 463.295 468.322 472.712C467.702 481.888 466.484 491.014 464.676 500.032V499.842C466.274 505.071 468.195 510.196 470.43 515.187C471.243 516.915 471.986 518.332 472.487 519.265C472.786 519.714 473.057 520.182 473.299 520.664C472.922 520.268 472.586 519.833 472.297 519.368C471.466 518.082 470.71 516.75 470.033 515.377C467.651 510.423 465.624 505.307 463.968 500.067V499.98V499.877C465.656 490.875 466.81 481.781 467.424 472.643C468.08 463.26 468.167 454.465 468.063 447.034V446.585H468.512C474.406 447.319 480.2 448.698 485.792 450.698C487.814 451.441 489.352 452.115 490.406 452.581C490.991 452.835 491.552 453.142 492.082 453.497Z"
          fill="#53277B"
        />
        <path
          d="M493.396 460.996C492.16 460.767 490.946 460.432 489.767 459.994C487.555 459.338 484.462 458.474 481.023 457.679C474.111 456.054 468.478 455.018 468.512 454.758C469.777 454.71 471.042 454.82 472.279 455.087C474.595 455.415 477.757 455.968 481.23 456.815C488.194 458.37 493.517 460.755 493.396 460.996Z"
          fill="#53277B"
        />
        <path
          d="M485.17 434.403C485.17 434.524 483.062 433.919 479.692 433.124C475.302 432.098 470.844 431.388 466.352 430.998C461.857 430.647 457.343 430.589 452.839 430.826C450.967 431 449.086 431.07 447.206 431.033C447.682 430.845 448.182 430.729 448.692 430.687C449.66 430.532 451.06 430.342 452.788 430.186C457.321 429.768 461.883 429.739 466.421 430.1C470.962 430.481 475.457 431.285 479.848 432.502C481.576 432.986 482.872 433.418 483.771 433.78C484.267 433.912 484.74 434.122 485.17 434.403Z"
          fill="#53277B"
        />
        <path
          d="M449.936 442.196C449.195 442.505 448.425 442.742 447.638 442.904C446.152 443.302 443.975 443.786 441.244 444.269C434.145 445.51 426.919 445.87 419.731 445.341C415.566 444.926 411.402 444.477 408.153 442.438C406.622 441.482 405.375 440.133 404.542 438.532C403.852 437.092 403.646 435.468 403.954 433.901C404.304 431.733 404.95 429.622 405.872 427.629C406.164 426.874 406.578 426.174 407.099 425.555C407.099 425.555 406.754 426.333 406.201 427.75C405.422 429.76 404.877 431.853 404.576 433.988C404.356 435.411 404.586 436.866 405.233 438.152C406.051 439.596 407.245 440.791 408.689 441.608C411.713 443.475 415.774 443.924 419.904 444.321C427.031 444.839 434.194 444.567 441.262 443.509C443.975 443.129 446.169 442.732 447.673 442.438C448.418 442.29 449.176 442.209 449.936 442.196Z"
          fill="#53277B"
        />
        <path
          d="M445.53 132.469C437.27 114.567 416.828 118.092 407.047 122.844C397.218 127.485 389.499 135.66 385.43 145.74C382.562 152.652 380.246 160.532 374.475 164.748C371.821 166.324 369.081 167.749 366.267 169.016C364.821 169.717 363.553 170.736 362.557 171.995C361.56 173.255 360.861 174.724 360.512 176.291C359.648 182.253 365.696 186.383 370.673 188.785C367.683 190.824 366.906 195.437 367.96 199.153C369.236 202.703 371.25 205.942 373.87 208.657C382.685 218.39 392.769 226.893 403.851 233.937C413.285 239.813 419.402 240.504 429.529 236.287C433.641 234.559 437.477 231.76 441.867 231.259C446.964 230.689 451.837 233.298 456.883 234.352C461.929 235.406 468.288 233.955 469.929 228.65C470.336 226.535 470.567 224.391 470.62 222.239C470.88 220.113 471.934 217.798 473.852 217.314C475.407 216.917 476.962 217.867 478.483 218.368C482.129 219.578 487.624 246.638 491.115 244.996C491.115 244.996 496.195 199.135 486.414 172.3C483.287 163.66 476.617 145.481 471.294 138.379C463.968 128.633 446.411 130.793 445.53 132.521"
          fill="#1B0134"
        />
        <path
          d="M435.058 135.441C422.046 130.897 404.49 140.884 400.498 154.069C396.057 168.705 391.236 186.763 390.39 198.271C388.662 221.409 405.129 228.269 405.129 228.269L398.442 264.436L454.827 246.206L474.509 160.981L476.34 153.827L435.058 135.441Z"
          fill="#C68F73"
        />
        <path
          d="M400.498 171.902C400.688 172.403 403.367 171.677 406.304 172.835C409.242 173.993 410.832 176.291 411.281 176.084C411.73 175.876 411.419 175.22 410.745 174.166C409.819 172.78 408.485 171.717 406.926 171.124C405.401 170.504 403.715 170.395 402.123 170.813C400.965 171.245 400.498 171.677 400.498 171.902Z"
          fill="#1B0134"
        />
        <path
          d="M429.684 179.592C430.03 179.989 432.363 178.503 435.508 178.728C438.653 178.952 440.864 180.68 441.244 180.317C441.625 179.955 441.106 179.436 440.139 178.589C438.837 177.561 437.25 176.958 435.594 176.861C433.95 176.744 432.311 177.149 430.911 178.019C429.874 178.728 429.529 179.402 429.684 179.592Z"
          fill="#1B0134"
        />
        <path
          d="M416.586 192.534C415.085 191.642 413.477 190.945 411.799 190.461C411.039 190.184 410.33 189.856 410.33 189.286C410.45 188.46 410.824 187.693 411.402 187.091L415.117 181.648C420.301 173.889 424.327 167.427 424.016 167.219C423.705 167.012 419.212 173.129 413.977 180.888C412.715 182.823 411.506 184.637 410.348 186.4C409.622 187.228 409.217 188.289 409.207 189.389C409.259 189.692 409.378 189.979 409.554 190.23C409.73 190.481 409.96 190.689 410.227 190.841C410.641 191.051 411.075 191.218 411.523 191.342C413.153 191.954 414.854 192.354 416.586 192.534Z"
          fill="#1B0134"
        />
        <path
          d="M405.147 228.269C415.853 230.965 427.092 230.684 437.65 227.457C437.65 227.457 426.712 241.385 404.093 233.92L405.147 228.269Z"
          fill="#A05A36"
        />
        <path
          d="M414.91 202.868C414.616 202.488 415.048 203.334 414.91 202.868V202.868Z"
          fill="#A05A36"
        />
        <path
          d="M424.949 201.364C424.611 200.653 424.129 200.019 423.535 199.501C422.94 198.984 422.245 198.595 421.493 198.358C420.476 197.993 419.383 197.892 418.317 198.064C417.25 198.237 416.244 198.676 415.394 199.343C415.045 199.839 414.816 200.409 414.723 201.008C414.63 201.608 414.676 202.22 414.858 202.799C415.947 204.198 416.05 205.373 417.571 206.255C418.666 206.616 419.826 206.735 420.972 206.604C422.118 206.472 423.221 206.094 424.206 205.494C424.738 204.965 425.095 204.285 425.228 203.546C425.361 202.808 425.263 202.046 424.949 201.364Z"
          fill="#A05A36"
        />
        <path
          d="M425.157 196.785C424.656 196.647 423.861 199.982 420.457 201.676C417.052 203.369 413.545 202.177 413.424 202.643C413.424 202.833 414.063 203.473 415.566 203.87C417.513 204.359 419.569 204.144 421.372 203.265C423.11 202.415 424.449 200.921 425.105 199.101C425.571 197.736 425.381 196.82 425.157 196.785Z"
          fill="#1B0134"
        />
        <path
          d="M436.043 163.297C435.87 164.178 438.445 165.025 441.054 166.995C443.664 168.965 445.443 170.986 446.238 170.451C446.584 170.209 446.567 169.293 446.048 168.066C445.348 166.542 444.257 165.23 442.886 164.264C441.554 163.279 439.975 162.681 438.324 162.536C436.959 162.605 436.13 162.882 436.043 163.297Z"
          fill="#1B0134"
        />
        <path
          d="M402.399 160.065C403.073 160.722 405.44 159.737 408.343 159.426C411.246 159.115 413.786 159.426 414.288 158.596C414.495 158.199 414.046 157.525 412.888 156.868C411.334 156.172 409.615 155.926 407.929 156.16C406.243 156.337 404.642 156.99 403.315 158.044C402.347 158.977 402.088 159.737 402.399 160.065Z"
          fill="#1B0134"
        />
        <path
          d="M479.52 153.775C478.549 152.045 477.029 150.689 475.2 149.922C463.982 143.766 452.367 138.362 440.432 133.748L435.525 136.53C434.881 141.31 435.67 146.174 437.793 150.505C439.915 154.837 443.276 158.44 447.448 160.86C450.8 162.726 455.155 164.489 455.587 168.308C456.019 172.127 451.889 174.961 449.556 178.175C448.13 180.181 447.331 182.566 447.26 185.026C447.189 187.487 447.85 189.914 449.159 191.999C450.662 194.332 452.943 196.094 454.343 198.496C455.21 200.126 455.702 201.929 455.783 203.774C455.864 205.619 455.531 207.458 454.809 209.158C453.41 212.614 451.077 217.158 447.897 219.076C452.19 220.041 456.676 219.642 460.731 217.936C464.786 216.229 468.207 213.299 470.517 209.555C473.143 205.097 473.351 199.688 473.696 194.522C474.49 182.769 476.358 171.114 479.278 159.702C480.014 157.808 480.099 155.723 479.52 153.775Z"
          fill="#1B0134"
        />
        <path
          d="M441.21 134.284C438.188 138.283 434.555 141.782 430.445 144.652C426.194 147.295 420.768 148.54 416.119 146.673C417.277 145.395 418.452 144.116 419.575 142.82C411.304 148.484 401.939 152.357 392.083 154.19C398.269 146.673 404.646 139.001 413.095 134.145C421.545 129.29 432.622 127.804 440.864 132.97"
          fill="#1B0134"
        />
        <path
          d="M480.66 177.985L454.326 198.444C457.35 201.745 456.174 210.143 453.202 213.478C450.23 216.813 446.29 219.664 445.133 223.984C443.854 228.701 446.359 233.592 446.429 238.482C446.532 245.394 441.884 251.338 438.29 257.248C434.695 263.158 432.103 271.072 436.112 276.723C440.622 283.082 450.835 282.166 457.004 277.414C463.173 272.662 466.698 265.318 471.277 259.028C475.856 252.738 483.01 247.917 490.769 248.66C493.175 224.63 489.706 200.376 480.66 177.985Z"
          fill="#1B0134"
        />
        <path
          d="M485.067 235.026C484.911 234.732 486.466 233.609 489.127 231.466C492.884 228.566 496.232 225.171 499.081 221.375C500.961 218.787 502.457 215.941 503.522 212.925C504.749 209.541 505.278 205.943 505.077 202.349C504.802 198.433 503.561 194.645 501.465 191.325C499.124 187.688 496.158 184.494 492.704 181.89C485.62 176.378 477.152 173.25 469.757 169.949C462.873 167.29 456.375 163.722 450.438 159.34C446.386 156.316 443.033 152.456 440.605 148.021C438.998 144.824 438.739 142.837 438.981 142.837C439.223 142.837 440.139 144.444 442.091 147.174C444.914 150.969 448.376 154.243 452.321 156.851C458.288 160.642 464.672 163.732 471.346 166.061C475.113 167.565 479.14 169.12 483.252 171.021C487.612 172.982 491.734 175.435 495.538 178.33C499.415 181.274 502.723 184.898 505.302 189.027C507.719 192.991 509.092 197.503 509.293 202.142C509.408 206.284 508.655 210.405 507.081 214.238C505.753 217.525 503.923 220.585 501.655 223.31C498.428 227.16 494.543 230.407 490.182 232.9C487.054 234.663 485.17 235.268 485.067 235.026Z"
          fill="#1B0134"
        />
        <path
          d="M485.568 172.973C484.777 172.81 484.006 172.56 483.27 172.23C481.818 171.695 479.814 170.883 477.17 169.811C470.426 167.139 464.092 163.532 458.352 159.098C452.586 154.568 448.249 148.473 445.858 141.541C445.163 139.445 444.75 137.266 444.631 135.061C444.6 134.486 444.6 133.909 444.631 133.333C444.603 133.127 444.603 132.917 444.631 132.711C444.897 135.645 445.476 138.541 446.359 141.351C448.903 148.058 453.214 153.951 458.836 158.406C464.528 162.776 470.745 166.416 477.342 169.241L483.321 171.902C484.102 172.189 484.854 172.548 485.568 172.973Z"
          fill="#53277B"
        />
        <path
          d="M486.397 199.135C486.293 199.135 486.829 198.099 487.106 196.163C487.413 193.395 486.927 190.597 485.706 188.093C483.919 184.368 481.161 181.192 477.723 178.9C473.938 176.153 469.238 173.976 464.538 171.159C460.095 168.518 456.114 165.167 452.753 161.24C450 158.036 447.677 154.487 445.841 150.682C444.618 148.129 443.651 145.46 442.955 142.716C442.679 141.748 442.575 140.988 442.471 140.47C442.391 140.213 442.356 139.944 442.368 139.675C442.728 140.642 443.034 141.628 443.284 142.63C444.107 145.305 445.153 147.906 446.411 150.406C448.27 154.139 450.593 157.622 453.323 160.774C456.65 164.611 460.57 167.89 464.935 170.485C469.566 173.25 474.301 175.479 478.137 178.313C481.663 180.713 484.461 184.037 486.224 187.921C487.433 190.538 487.836 193.455 487.382 196.301C487.249 197.063 487.022 197.806 486.708 198.513C486.553 198.893 486.432 199.153 486.397 199.135Z"
          fill="#53277B"
        />
        <path
          d="M340.779 181.613C340.779 181.613 340.779 181.51 340.934 181.337C341.107 181.071 341.297 180.817 341.504 180.577C342.297 179.628 343.304 178.882 344.442 178.399C346.275 177.757 348.202 177.424 350.144 177.414C352.373 177.293 354.931 177.121 357.748 177.121C360.794 177.175 363.836 176.891 366.82 176.274C370.186 175.409 373.304 173.77 375.926 171.487C378.815 168.923 381.317 165.955 383.356 162.675C385.525 159.119 387.504 155.45 389.284 151.684C391.1 147.893 393.179 144.232 395.504 140.729C397.701 137.437 400.462 134.559 403.66 132.227C409.171 128.385 415.693 126.257 422.409 126.11C426.901 125.954 431.375 126.743 435.542 128.426L437.477 129.272L438.808 130.033C439.189 130.227 439.553 130.452 439.897 130.707C439.494 130.574 439.106 130.4 438.739 130.188L437.356 129.514L435.421 128.754C431.267 127.224 426.849 126.543 422.427 126.749C415.866 126.988 409.515 129.125 404.144 132.901C401.037 135.191 398.358 138.01 396.23 141.23C393.949 144.685 391.911 148.293 390.13 152.03C388.33 155.826 386.328 159.524 384.134 163.107C382.04 166.448 379.467 169.464 376.496 172.058C373.767 174.408 370.523 176.084 367.027 176.948C363.984 177.551 360.883 177.806 357.782 177.708C354.965 177.708 352.443 177.708 350.196 177.864C348.304 177.827 346.418 178.101 344.615 178.676C343.497 179.099 342.492 179.773 341.677 180.646C341.398 180.986 341.098 181.309 340.779 181.613Z"
          fill="#53277B"
        />
        <path
          d="M330.203 158.303C319.835 132.124 306.011 106.463 304.11 86.9536L283.461 87.7831C283.893 95.3344 286.606 158.182 301.242 183.03C315.878 207.879 364.746 265.974 364.746 265.974L381.939 221.755C381.939 221.755 340.588 184.499 330.203 158.303Z"
          fill="#C68F73"
        />
        <path
          d="M295.781 46.3111H286.744C286.744 46.3111 287.625 51.6333 291.721 51.9271C295.816 52.2208 295.021 51.2877 295.021 51.2877L294.105 52.1344L288.472 53.2403C288.472 53.2403 288.748 57.6295 292.464 58.3207L297.941 53.2403L295.781 46.3111Z"
          fill="#C68F73"
        />
        <path
          d="M320.526 45.5162C319.697 52.3245 319.611 51.9098 319.611 51.9098L311.351 58.7181L309.243 48.6957C309.107 48.0316 308.755 47.431 308.243 46.9872C307.73 46.5435 307.085 46.2815 306.409 46.2419L302.659 46C302.659 46 304.11 40.816 299.393 41.248C299.393 41.248 296.801 40.5395 294.71 49.2141C292.429 58.7008 282.614 80.992 283.461 88.4397L304.404 89.2864C304.18 82.3744 309.882 74.9959 312.699 71.8163C313.549 70.8697 314.144 69.7218 314.427 68.4813C314.814 66.777 315.622 65.1968 316.777 63.8848C319.369 61.0163 324.812 55.8496 324.812 55.8496V40.073C324.812 40.073 321.321 38.9498 320.526 45.5162ZM303.73 60.7571L299.428 62.7098L300.153 58.3207L303.73 57.9751V60.7571Z"
          fill="#C68F73"
        />
        <path
          d="M299.203 63.1763C299.2 62.5541 299.27 61.9336 299.41 61.3274C299.618 60.1351 299.911 58.4416 300.309 56.3335L301.76 48.9722C302.101 47.5572 302.338 46.1191 302.469 44.6695C302.469 43.2698 301.95 41.6627 300.447 41.5763C298.944 41.4899 297.838 42.5095 297.112 43.7363C296.766 44.3737 296.488 45.0455 296.283 45.7408L295.609 47.7971L293.362 54.9511C292.688 56.9901 292.066 58.6144 291.634 59.7203C291.438 60.3344 291.152 60.9165 290.788 61.4483C290.888 60.853 291.062 60.2725 291.306 59.7203C291.669 58.5799 292.205 56.9383 292.792 54.8992C293.38 52.8602 294.088 50.3373 294.848 47.6762C295.073 46.985 295.28 46.2938 295.505 45.5853C295.715 44.8285 296.011 44.0981 296.386 43.408C296.805 42.6789 297.356 42.0342 298.011 41.5072C298.376 41.2198 298.795 41.009 299.243 40.8872C299.691 40.7655 300.16 40.7354 300.62 40.7987C301.082 40.8735 301.527 41.0316 301.933 41.2653C302.323 41.5379 302.643 41.8991 302.866 42.3194C303.243 43.0929 303.421 43.9481 303.385 44.8077C303.242 46.306 302.982 47.7909 302.607 49.2487L300.879 56.5927C300.413 58.6663 300.015 60.3597 299.704 61.5175C299.61 62.0898 299.441 62.6474 299.203 63.1763Z"
          fill="#A05A36"
        />
        <path
          d="M303.488 60.809C303.278 59.1241 303.192 57.4261 303.229 55.7287C303.229 55.0375 303.125 54.3808 303.074 53.776C303.101 53.3209 302.96 52.8717 302.676 52.5146C302.089 52.0826 301.38 52.0826 301.415 51.9271C301.449 51.7715 301.57 51.8061 301.847 51.7715C302.264 51.7129 302.688 51.7917 303.056 51.9962C303.314 52.2044 303.523 52.4675 303.666 52.7665C303.81 53.0654 303.885 53.3926 303.886 53.7242C304.015 54.3914 304.096 55.0671 304.128 55.7459C304.369 57.4604 304.148 59.2083 303.488 60.809Z"
          fill="#A05A36"
        />
        <path
          d="M313.753 56.9555C313.753 56.9555 313.182 57.7159 312.025 58.6835C310.562 59.9797 308.935 61.0778 307.186 61.9495C305.364 62.7399 303.416 63.202 301.432 63.3146C300.628 63.4716 299.8 63.4716 298.996 63.3146C299.742 62.9963 300.536 62.8036 301.346 62.7443C303.225 62.461 305.06 61.9383 306.806 61.1891C308.525 60.3565 310.168 59.3735 311.714 58.2515C312.889 57.353 313.666 56.8519 313.753 56.9555Z"
          fill="#A05A36"
        />
        <path
          d="M477.964 253.222C477.964 253.222 501.223 252.531 515.151 248.383C529.079 244.236 582.543 231.518 582.543 231.518L668.338 217.21L670.066 239.398C670.066 239.398 590.354 262.708 574.871 268.013C559.388 273.318 488.626 297.597 475.251 294.521L466.957 277.466L477.964 253.222Z"
          fill="#C68F73"
        />
        <path
          d="M709.585 191.117C708.186 190.806 701.239 193.969 701.239 193.969C701.901 193.166 702.274 192.164 702.299 191.124C702.324 190.084 702 189.065 701.377 188.232L691.839 192.915C689.318 195.471 686.658 197.888 683.873 200.155C679.485 204.197 675.439 208.595 671.777 213.305L666.074 218.61L669.617 239.605C672.112 239.813 674.622 239.483 676.978 238.637C679.933 237.272 684.253 231.725 684.253 231.725L692.392 231.034C693.339 230.965 694.243 230.61 694.984 230.015C695.718 229.299 696.281 228.427 696.631 227.464C696.981 226.5 697.109 225.47 697.006 224.451C702.19 221.513 699.166 217.78 699.166 217.78C700.803 217.579 702.3 216.756 703.347 215.482C703.791 214.792 704.005 213.979 703.959 213.159C703.912 212.34 703.607 211.556 703.088 210.92C702.121 209.192 694.656 209.728 694.656 209.728L694.258 207.533C694.258 207.533 705.663 198.479 708.22 196.509C710.778 194.539 710.899 191.411 709.585 191.117Z"
          fill="#C68F73"
        />
        <path
          d="M701.377 193.709C700.557 195.347 699.375 196.777 697.921 197.891C696.443 199.155 695.052 200.518 693.757 201.969C692.319 203.836 691.279 205.976 690.698 208.259C690.098 210.649 689.24 212.966 688.141 215.171C687.198 216.934 686.037 218.572 684.685 220.044C683.771 221.025 682.771 221.921 681.696 222.723C681.321 223.041 680.894 223.292 680.434 223.466C680.33 223.345 682.058 222.152 684.218 219.612C685.465 218.132 686.537 216.515 687.415 214.791C688.448 212.641 689.269 210.395 689.869 208.086C690.475 205.689 691.589 203.45 693.135 201.52C694.487 200.062 695.955 198.715 697.524 197.494C698.884 196.311 700.171 195.048 701.377 193.709Z"
          fill="#A05A36"
        />
        <path
          d="M697.179 224.399C696.018 225.185 694.733 225.77 693.377 226.127C691.911 226.62 690.391 226.933 688.85 227.06C687.935 227.129 687.017 227.129 686.102 227.06C685.058 226.987 684.051 226.642 683.182 226.058C682.73 225.736 682.363 225.311 682.11 224.818C681.858 224.325 681.727 223.778 681.73 223.224C681.923 222.229 682.462 221.336 683.251 220.701C683.882 220.098 684.654 219.664 685.497 219.439C686.258 219.232 686.949 219.077 687.588 218.956C688.942 218.546 690.358 218.382 691.77 218.472C691.77 218.575 690.215 218.886 687.726 219.56L685.722 220.148C685.001 220.368 684.348 220.766 683.821 221.306C683.219 221.817 682.795 222.507 682.612 223.276C682.615 223.682 682.718 224.082 682.91 224.44C683.103 224.799 683.38 225.105 683.717 225.332C685.279 226.144 687.049 226.469 688.798 226.265C690.282 226.178 691.754 225.935 693.187 225.539C695.658 224.9 697.127 224.26 697.179 224.399Z"
          fill="#A05A36"
        />
        <path
          d="M699.442 217.78C698.539 218.285 697.536 218.586 696.505 218.662C695.403 218.85 694.286 218.931 693.17 218.904C692.436 218.956 691.7 218.847 691.014 218.585C690.327 218.322 689.706 217.912 689.195 217.383C688.758 216.761 688.508 216.026 688.478 215.266C688.447 214.506 688.636 213.753 689.022 213.098C689.605 212.07 690.493 211.249 691.563 210.748C692.467 210.221 693.489 209.93 694.535 209.901C694.535 210.039 693.446 210.385 691.874 211.3C690.979 211.813 690.249 212.568 689.765 213.478C689.475 213.99 689.332 214.574 689.353 215.162C689.375 215.751 689.559 216.323 689.886 216.813C690.325 217.237 690.845 217.568 691.415 217.785C691.986 218.002 692.594 218.1 693.204 218.074C694.28 218.145 695.36 218.145 696.435 218.074C697.429 217.901 698.434 217.803 699.442 217.78Z"
          fill="#A05A36"
        />
        <path
          d="M693.809 230.74C693.054 231.118 692.243 231.369 691.407 231.484C689.439 232.031 687.4 232.276 685.359 232.209C685.012 232.225 684.666 232.164 684.347 232.029C684.027 231.895 683.741 231.691 683.51 231.432C683.136 230.935 682.947 230.324 682.974 229.704C683.013 229.197 683.164 228.705 683.414 228.263C683.665 227.821 684.011 227.44 684.426 227.146C685.946 226.23 687.052 226.801 686.983 226.87C686.203 226.952 685.456 227.225 684.806 227.665C684.504 227.922 684.259 228.239 684.086 228.596C683.914 228.953 683.817 229.342 683.804 229.738C683.795 230.167 683.929 230.586 684.184 230.931C684.34 231.095 684.532 231.221 684.745 231.299C684.957 231.377 685.185 231.405 685.411 231.38C687.379 231.424 689.347 231.274 691.286 230.931C692.107 230.707 692.963 230.642 693.809 230.74Z"
          fill="#A05A36"
        />
        <path
          d="M438.514 326.886C438.018 327.27 437.471 327.584 436.89 327.82C435.359 328.533 433.769 329.111 432.138 329.548C426.705 331.135 420.974 331.419 415.411 330.377C409.855 329.33 404.633 326.959 400.187 323.465C398.852 322.422 397.609 321.265 396.472 320.009C396.031 319.564 395.647 319.064 395.332 318.523C395.47 318.402 397.163 320.458 400.516 322.981C404.97 326.264 410.099 328.513 415.532 329.565C420.966 330.586 426.558 330.403 431.913 329.029C436.043 327.975 438.411 326.765 438.514 326.886Z"
          fill="#502C3B"
        />
        <path
          d="M399.237 262.985C399.237 262.985 453.911 245.498 455.95 244.945C457.989 244.392 506.217 248.694 506.217 248.694C506.217 248.694 504.178 280.645 516.274 290.996L480.798 303.783L469.618 334.559C469.618 334.559 463.38 347.104 468.339 358.872C477.722 381.146 494 416.293 491.598 420.319C491.339 420.734 489.87 408.223 466.732 401.588C459.717 399.601 446.981 399.566 435.456 401.899C385.862 411.887 366.716 434.731 361.152 438.723C361.152 438.723 371.364 358.595 366.543 346.084C361.722 333.574 356.607 325.348 360.581 314.272C364.556 303.196 370.517 285.293 370.517 285.293L350.974 263.175L378.276 217.78L403.263 238.516L399.237 262.985Z"
          fill="#502C3B"
        />
        <path
          d="M417.415 337.22C416.79 337.301 416.157 337.301 415.532 337.22C413.817 337.15 412.111 336.931 410.434 336.563C404.852 335.35 399.704 332.645 395.539 328.735C391.388 324.8 388.465 319.746 387.124 314.186C386.725 312.484 386.494 310.748 386.432 309.002C386.378 308.378 386.413 307.749 386.536 307.135C386.808 309.426 387.211 311.7 387.746 313.944C389.228 319.307 392.136 324.168 396.161 328.01C400.231 331.801 405.195 334.5 410.59 335.855C414.737 337.133 417.415 337.133 417.415 337.22Z"
          fill="#1B0134"
        />
        <path
          d="M366.076 269.482C368.206 272.2 369.524 275.465 369.878 278.9C370.526 282.293 370.215 285.8 368.979 289.026C369.316 285.705 369.316 282.359 368.979 279.038C368.337 275.763 367.364 272.561 366.076 269.482Z"
          fill="#1B0134"
        />
        <path
          d="M482.112 278.952C482.112 279.09 480.988 279.868 479.33 281.475C477.183 283.553 475.452 286.022 474.232 288.749C473.02 291.473 472.362 294.41 472.297 297.389C472.297 299.688 472.521 301.105 472.297 301.139C471.805 299.951 471.564 298.675 471.588 297.389C471.459 294.284 472.051 291.191 473.316 288.352C474.568 285.509 476.44 282.982 478.794 280.956C479.753 280.071 480.882 279.389 482.112 278.952Z"
          fill="#1B0134"
        />
        <g opacity={0.3}>
          <path
            d="M409.294 336.028C404.02 334.98 399.223 332.266 395.608 328.286C393.155 325.929 391.147 323.148 389.681 320.078C388.437 325.504 395.988 335.129 403.349 338.671C407.081 340.41 411.166 341.257 415.281 341.143C419.396 341.029 423.428 339.958 427.058 338.015C421.085 337.951 415.133 337.286 409.294 336.028Z"
            fill="black"
          />
        </g>
        <path
          d="M476.254 375.737C476.254 375.841 474.82 374.752 471.986 373.473C467.952 371.733 463.608 370.828 459.216 370.812C452.682 370.772 446.21 372.084 440.208 374.666C432.926 377.946 425.981 381.928 419.472 386.554C407.472 394.872 394.673 401.975 381.266 407.757C376.082 409.951 371.865 411.524 368.91 412.543C367.442 413.079 366.284 413.442 365.454 413.684C365.064 413.836 364.658 413.946 364.245 414.012C364.611 413.803 364.999 413.635 365.403 413.511L368.755 412.215C371.675 411.092 375.874 409.45 380.972 407.169C394.282 401.25 407.009 394.1 418.988 385.811C425.544 381.142 432.555 377.148 439.914 373.888C446.028 371.309 452.616 370.043 459.25 370.173C463.716 370.247 468.115 371.265 472.158 373.162C473.625 373.848 475 374.713 476.254 375.737Z"
          fill="white"
        />
        <path
          d="M466.37 347.087C465.331 346.164 464.235 345.31 463.086 344.529C459.862 342.553 456.2 341.401 452.425 341.177C446.817 340.911 441.224 341.94 436.078 344.184C429.751 346.931 423.573 350.012 417.571 353.411C411.512 356.777 405.264 359.789 398.857 362.431C393.58 364.546 388.069 366.02 382.441 366.82C378.65 367.35 374.808 367.414 371.001 367.011C369.968 366.897 368.941 366.73 367.925 366.509C367.565 366.458 367.211 366.365 366.871 366.233C366.871 366.147 368.357 366.44 371.036 366.665C374.814 366.923 378.61 366.767 382.354 366.198C387.906 365.311 393.338 363.792 398.546 361.671C404.899 359.009 411.106 356.01 417.139 352.685C423.174 349.276 429.393 346.201 435.767 343.475C441.038 341.232 446.759 340.25 452.476 340.607C456.335 340.889 460.055 342.163 463.276 344.305C464.46 345.046 465.507 345.988 466.37 347.087Z"
          fill="white"
        />
        <path
          d="M393.379 325.314C393.379 325.314 393.016 325.677 392.273 326.212C391.823 326.546 391.35 326.847 390.856 327.111C390.227 327.496 389.575 327.842 388.903 328.148C387.158 328.968 385.334 329.611 383.46 330.066C381.16 330.612 378.808 330.913 376.445 330.964C374.075 330.96 371.713 330.711 369.394 330.221C367.509 329.832 365.668 329.253 363.899 328.493C363.219 328.209 362.556 327.886 361.912 327.526C361.411 327.278 360.931 326.989 360.478 326.662C359.717 326.161 359.32 325.85 359.355 325.798C359.389 325.746 361.083 326.748 364.141 327.906C365.898 328.568 367.707 329.082 369.55 329.444C371.83 329.86 374.144 330.063 376.462 330.049C378.758 329.996 381.043 329.724 383.287 329.236C385.126 328.82 386.925 328.242 388.661 327.508C391.616 326.316 393.275 325.176 393.379 325.314Z"
          fill="white"
        />
        <path
          d="M474.612 320.977C474.414 321.201 474.187 321.398 473.938 321.564C473.253 322.081 472.524 322.537 471.761 322.929C468.946 324.393 465.868 325.28 462.706 325.539C458.03 325.908 453.326 325.475 448.796 324.26C443.612 323.016 438.238 320.994 432.449 319.352C422.522 316.593 412.131 315.923 401.932 317.382C398.838 317.822 395.774 318.451 392.757 319.266C390.631 319.836 389.456 320.199 389.439 320.13C389.422 320.061 389.698 319.974 390.251 319.767C390.804 319.56 391.599 319.249 392.67 318.938C395.665 317.988 398.727 317.26 401.829 316.76C412.12 315.092 422.65 315.681 432.691 318.488C438.531 320.216 443.923 322.186 448.986 323.448C453.43 324.685 458.049 325.175 462.654 324.899C465.753 324.698 468.788 323.925 471.605 322.618C473.593 321.668 474.56 320.89 474.612 320.977Z"
          fill="white"
        />
        <path
          d="M473.178 295.039C472.817 295.137 472.446 295.195 472.072 295.212C471.346 295.212 470.344 295.42 468.875 295.523C464.946 295.619 461.017 295.336 457.142 294.676C452.235 293.882 446.498 292.43 440.173 290.719C433.392 288.709 426.457 287.264 419.437 286.399C417.592 286.248 415.741 286.19 413.89 286.227C412.974 286.227 412.076 286.227 411.177 286.313L408.533 286.624C405.167 287.102 401.86 287.93 398.667 289.095C392.515 291.272 387.141 293.778 382.423 295.368C378.73 296.722 374.87 297.571 370.949 297.891C369.886 297.986 368.816 297.986 367.753 297.891C367.375 297.901 366.998 297.866 366.629 297.787C367.003 297.758 367.379 297.758 367.753 297.787C368.81 297.792 369.866 297.729 370.915 297.597C374.784 297.148 378.581 296.213 382.216 294.815C386.864 293.173 392.187 290.616 398.39 288.369C401.626 287.165 404.98 286.302 408.395 285.795L411.108 285.466C412.024 285.466 412.94 285.466 413.873 285.466C415.752 285.429 417.633 285.487 419.506 285.639C426.588 286.51 433.583 287.984 440.415 290.045C446.722 291.773 452.407 293.329 457.28 294.193C461.111 294.905 464.997 295.275 468.892 295.299L472.072 295.178C472.434 295.088 472.805 295.042 473.178 295.039Z"
          fill="white"
        />
        <path
          d="M490.354 248.677C490.51 248.764 488.747 251.286 487.088 255.866C484.916 261.84 484.321 268.274 485.36 274.545C486.488 280.797 489.175 286.663 493.171 291.601C496.247 295.385 498.718 297.217 498.597 297.355C497.974 297.009 497.394 296.591 496.869 296.111C495.363 294.865 493.969 293.489 492.704 291.998C488.476 287.071 485.636 281.107 484.479 274.718C483.408 268.32 484.089 261.752 486.449 255.71C487.164 253.908 488.037 252.174 489.058 250.526C489.416 249.861 489.851 249.24 490.354 248.677Z"
          fill="white"
        />
        <path
          d="M399.202 254.189C398.719 254.391 398.205 254.508 397.682 254.535C396.269 254.77 394.844 254.926 393.413 255.002C391.275 255.14 389.128 254.971 387.037 254.5C384.405 253.902 382.004 252.551 380.125 250.612C378.121 248.643 376.669 246.5 375.062 244.72C373.732 243.132 372.157 241.767 370.396 240.676C368.744 239.728 366.891 239.183 364.988 239.087C365.492 238.908 366.03 238.849 366.56 238.914C368.017 238.996 369.435 239.41 370.707 240.124C372.594 241.171 374.279 242.546 375.684 244.184C377.291 245.912 378.829 248.09 380.747 249.973C382.5 251.783 384.719 253.075 387.158 253.706C389.171 254.19 391.239 254.411 393.31 254.362C397.042 254.189 399.202 253.965 399.202 254.189Z"
          fill="white"
        />
      </g>
      <path
        d="M778.153 785.148C778.153 785.39 623.151 785.597 432.017 785.597C240.883 785.597 85.8472 785.39 85.8472 785.148C85.8472 784.906 240.814 784.699 432.017 784.699C623.221 784.699 778.153 784.923 778.153 785.148Z"
        fill="#1B0134"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3837_3343"
        x={227.309}
        y={148}
        width={458.061}
        height={667}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={28} />
        <feGaussianBlur stdDeviation={18} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_3837_3343"
        />
      </filter>
      <filter
        id="filter1_d_3837_3343"
        x={239.409}
        y={-4}
        width={515}
        height={708.086}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={22} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3837_3343"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3837_3343"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3837_3343">
        <rect width={864} height={864} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkPaymentSideImage;
