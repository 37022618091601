import React, { createContext, useContext, useState, ReactNode } from 'react';
import { marked } from 'marked';
import { AddNewUserSessionId, getCookie } from '../utils/utils';
import { getSandraDomain } from '../utils/constants';
type Message = {
  id: string;
  type: string;
  content: string;
  sender: string;
};

type ChatContextType = {
  displayedMessages: Message[];
  setNewMessage: (message: Message) => void;
  componentMessage: Message | null;
  componentName: string | null;
  setComponentName: (name: string | null) => void;
  shouldComponentRender: boolean;
  setShouldComponentRender: (value: boolean) => void;
  sendMessageWithResponse: (message: string, client: string, sessionId: string) => void;
  componentTriggerMessageId: string | null;
  setComponentMessage: (message: Message | null) => void;
  isChatLoading: boolean;
};

const ChatContext = createContext<ChatContextType | undefined>(undefined);

let lastUpdatedSessionId = "";

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [displayedMessages, setDisplayedMessages] = useState<Message[]>([]);
  const [componentName, setComponentName] = useState<string | null>(null);
  const [componentMessage, setComponentMessage] = useState<Message | null>(
    null
  );
  const [shouldComponentRender, setShouldComponentRender] =
    useState<boolean>(false);
  const [componentTriggerMessageId, setComponentTriggerMessageId] = useState<
    string | null
  >(null);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);

  const setNewMessage = (message: Message) => {
    setDisplayedMessages((prev) => [...prev, message]);
  };

  const sendMessageWithResponse = async (message: string, client: string, sessionId: string) => {
    try {
      setIsChatLoading(true);
      // Add the user's message
      setNewMessage({
        type: 'text',
        content: message,
        sender: 'user',
        id: `${Date.now()}`,
      });

      const myHeaders = new Headers();
      const username: any = await localStorage.getItem('username');
      sessionId = username + "-" + sessionId;
      const token: any = getCookie('Authorized'); //await localStorage.getItem('accessToken');
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');
      const client_name = username == "lavenderuser" ? "lavender" : client
      const raw = JSON.stringify({
        input: `${message}`,
        username: `${username}`,
        client_name: client_name,
        sessionid: sessionId,
      });

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      fetch(
        getSandraDomain(),
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          const res = marked(result?.response || '');
          const responseMessage = {
            type: 'text',
            content: res,
            sender: 'assistant',
            metadata: result.metadata
          };
          setIsChatLoading(false);
          //@ts-ignore
          setNewMessage(responseMessage);
          if( lastUpdatedSessionId !== sessionId) {
            lastUpdatedSessionId = sessionId;
            // make the api call to store the session id
            AddNewUserSessionId(username, sessionId);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsChatLoading(false);
        });
    } catch (error) {
      console.error('Error: ' + error);
      setIsChatLoading(false);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        displayedMessages,
        setNewMessage,
        componentName,
        setComponentName,
        shouldComponentRender,
        setShouldComponentRender,
        sendMessageWithResponse,
        componentTriggerMessageId,
        componentMessage,
        setComponentMessage,
        isChatLoading,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
