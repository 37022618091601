import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import { RootState } from '../../store';
import {
  hideSideBar,
  openSideBar,
  setActiveSidebarIconId,
} from '../../store/sidebarSlice';
import { filteredSidebarBodyIcons } from '../../constants/data';
import IconWithText from './IconWithText';
import MobileHeader from './MobileHeader';
import AuthButton from '../login/auth-button';
import { useTheme } from '../../context/ThemeContext';
import { logoNameMap } from '../../assets/SVGs/logos/Logo';
import { isOvum } from '../../utils/utils';

const Sidebar: React.FC = () => {
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 767;
  const isMobile = window.innerWidth <= 767;
  const activeSidebarIconId = useSelector(
    (state: RootState) => state.sidebar.activeSidebarIconId
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const pathToIconIdMap: { [key: string]: number } = {
    '/': 1,
    '/asksandra': 2,
  };

  useEffect(() => {
    if (isTablet) {
      dispatch(openSideBar());
    } else if (window.innerWidth > 1024) {
      dispatch(openSideBar());
    }
  }, [dispatch]);

  useEffect(() => {
    const currentIconId = pathToIconIdMap[location.pathname];
    if (currentIconId && currentIconId !== activeSidebarIconId) {
      dispatch(setActiveSidebarIconId(currentIconId));
    }
  }, [location.pathname, activeSidebarIconId, dispatch]);

  if (!isOpen) {
    return <></>;
  }

  if(isOvum() && ["/ovumlanding"].includes(window.location.pathname)){
    return (<></>)
  }

  const onIconClickHandler = (icon: any) => {
    dispatch(setActiveSidebarIconId(icon.id));
    if (icon.path) {
      navigate(icon.path);
    }
    if (isTablet || isMobile) {
      dispatch(hideSideBar());
    }
  };

  const IconComponent = logoNameMap[theme.logo];
  return (
    <div className="fixed h-screen inset-0 z-40 flex md:relative md:inset-auto">
      {/* Overlay for Mobile */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50 md:hidden"
        onClick={() => dispatch(hideSideBar())}
      ></div>
      <div
        className={`bg-white h-full w-full md:w-[223px] transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0`}
      >
        <div
          className="hidden md:block ml-3 absolute top-5 flex justify-center items-center cursor-pointer"
          onClick={() => {
            !isOvum() && navigate('/');
          }}
        >
          <IconComponent width={200}/>
        </div>

        <MobileHeader />

        <div className="w-full h-full flex flex-col justify-start mt-5 md:mt-0 md:justify-center md:items-center">
          <div className="flex flex-col w-full">
            {filteredSidebarBodyIcons.map((icon, index) => (
              <div key={index} className="w-full px-3">
                {!icon.isHidden && 
                  <IconWithText
                    clickHandler={() => icon.isActive && onIconClickHandler(icon)}
                    iconName={icon.iconName}
                    text={icon.title}
                    isActive={icon.isActive}
                    iconColor={
                      activeSidebarIconId === icon.id ? theme.p_color : ''
                    }
                    className={`mb-10  md:mb-6 w-full rounded-xl p-[4px] px-2 cursor-pointer ${
                      activeSidebarIconId === icon.id ? 'bg-[#DCE1E8]' : ''
                    }`}
                    textClass={
                      activeSidebarIconId === icon.id
                        ? 'text-[#242E49] font-medium'
                        : ''
                    }
                    notificationNumber={icon.notification}
                  />
                }
              </div>
            ))}
          </div>
        </div>

        {/* { Logout Option } */}
        <AuthButton />
      </div>
    </div>
  );
};

export default Sidebar;
