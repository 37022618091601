import ArrowIcon from '../../assets/SVGs/ArrowIcon';
import { useTheme } from '../../context/ThemeContext';
import MultilineInputBox from './MultilineInputBox';
import QuestionTabs from './QuestionTabs';
import { SandraQnA } from './SandraQnA';
import { LOGGED_IN_QUESTIONS } from './constants';
import { customComponentNameMap } from './../../assets/SVGs/customIcons/index';
import { useNavigate } from 'react-router-dom';
import { isOvum } from '../../utils/utils';

type HomeProps = {
  isAuthenticated: boolean;
};
const Home: React.FC<HomeProps> = ({ isAuthenticated }) => {
  const { theme } = useTheme();
  const { Component1, Component2, Component3 } =
    customComponentNameMap[theme.logo];
  const navigate = useNavigate()

  if(isOvum()) {
    navigate('/ovumlanding');
  }

  return (
    <div className="min-h-[950px] md:min-h-screen overflow-hidden relative flex flex-col items-center bg-gray-10 p-4 md:p-8">
      {!isAuthenticated && (
        <>
          <div className="flex justify-center items-center w-full md:justify-end md:h-auto"></div>
          <SandraQnA />
          <div className="absolute bottom-20 z-10 md:relative md:bottom-auto">
            <button
              className={`px-24 mt-6 py-4 font-bold text-white text-sm md:text-lg rounded-[16px] md:w-auto flex items-center justify-center ${theme['button-bg']}`}
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                window.location.href = '/signup';
              }}
            >
              CREATE A FREE ACCOUNT{' '}
              <ArrowIcon style={{ marginLeft: '8px' }} color="white" />
            </button>
            <div
              className={`bg-white-40 bg-white p-[1px] mt-6 rounded-[16px] z-10 w-full border ${theme['home-button-border']}`}
            >
              <button
                className={`z-10 px-32 py-4 bg-white ${theme['home-button-text']} font-bold rounded-[16px] text-sm md:text-lg w-full flex items-center justify-center`}
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                SIGN IN{' '}
                <ArrowIcon color={theme.color} style={{ marginLeft: '8px' }} />
              </button>
            </div>
          </div>
          <div className="absolute bottom-20 md:bottom-0 right-45 md:right-0 w-[157px] md:w-[257px] z-0">
            <Component3 />
          </div>
          <div className="absolute bottom-0 left-10 flex justify-end items-end hidden md:flex">
            <Component1 />
            <div className="ml-5">
              <Component2 />
            </div>
          </div>
        </>
      )}
      {(isAuthenticated && !isOvum()) && (
        <>
          <div className="p-2 sm:p-4 md:p-8 lg:p-10 xl:p-16">
            {/* <AskSandra />{' '} */}
          </div>
          <MultilineInputBox />
          <QuestionTabs data={LOGGED_IN_QUESTIONS} />
        </>
      )}
    </div>
  );
};

export default Home;
