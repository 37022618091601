import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { usePayabli } from '../../../hooks/usePayabli';
// import { PayabaliPopUp } from '../../shared/PayabaliPopUp';
import { useTheme } from '../../../context/ThemeContext';
import { getPayabliSrc, getPayabliTokens, isStageOrDevEnv, postPaymentForLoginPayabli } from '../../../utils/utils';
import LoadingOverlay from '../../insurance/LoadingOverlay';
import { payabliConfigACH, payabliConfigCard } from '../../linkpayment/Payabli';

export default function Statement() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [providerDetails, setProviderDetails] = useState<any>({})
  const [error, setError] = useState<boolean>(false);
  const payabliComponentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isCardTab, setIsCardTab] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  
  const [statementData, setStatementData] = useState({
    id: '',
    statementNumber: '',
    provider: '',
    providerAddress: '',
    amountDue: 0,
    patientAddress: '',
    dates: {
      statement: '',
      due: '',
    },
    charges: {
      total: 0,
      adjustments: 0,
      insurancePayments: 0,
      patientPayments: 0,
    },
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    const bill = location?.state?.bill;
    const providerId = queryParams.get('providerId');
    const json = JSON.parse(bill.jsonData || '{}');
    setStatementData({
      id: bill.id || '',
      patientAddress: json.userAddress || '',
      statementNumber: bill.id || '',
      provider: bill.providerName || '',
      providerAddress: bill.providerAddress || '',
      amountDue: parseFloat(bill.amount || '0'),
      dates: {
        statement: bill.statementDate || '',
        due: bill.dueDate || '',
      },
      charges: {
        total: json.totalCharges || '0',
        adjustments: json.adjustments, // If available from API
        insurancePayments: json.insurancePayment, // If available from API
        patientPayments: json.patientPayment, // If available from API
      },
      firstName: bill.firstName || '',
      lastName: bill.lastName || ''
    });

    const fetchPayabliToken = async () => {
      if(!providerId) {
        setError(true);
        return;
      }
      try {
        setLoading(true);
        const response = await getPayabliTokens(providerId); // Replace with actual API endpoint
        setProviderDetails(response);
      } catch (error) {
        console.error('Error fetching bills:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchPayabliToken();
  }, [location.search]);

  useEffect(() => {
    if (!payabliComponentRef?.current) return;

    if (isCardTab) {
      // @ts-ignore
      payabliComponentRef.current.updateConfig(payabliConfigCard);
    } else {
      // @ts-ignore
      payabliComponentRef.current.updateConfig(payabliConfigACH);
    }
  }, [isCardTab]);

  const loadPayabliScript = () => {
    if (!document.querySelector('script[src*="payabli.com/component.js"]')) {
      const script = document.createElement("script");
      script.src = getPayabliSrc();
      if(isStageOrDevEnv()) {
        script.dataset.test = "true";
      }
      script.onload = initializePayabliForm;
      document.body.appendChild(script);
    } else {
      initializePayabliForm();
    }
    setIsOpen(true);
  };

  const initializePayabliForm = () => {
    // @ts-ignore
    if (typeof PayabliComponent === "undefined") {
      console.error("Payabli script not loaded.");
      return;
    }
    // @ts-ignore
    payabliConfigCard.token = providerDetails?.token;
    // @ts-ignore
    payabliConfigCard.entryPoint = providerDetails?.entry;

    // @ts-ignore
    payabliConfigACH.token = providerDetails?.token;
    // @ts-ignore
    payabliConfigACH.entryPoint = providerDetails?.entry;


    payabliConfigCard.functionCallBackSuccess = async (response: any) => {
      setIsOpen(false);
      if(response && response.isSuccess) {
        try {
          setLoading(true);
          // @ts-ignore
          const res = await postPaymentForLoginPayabli(response?.responseData?.referenceId, "Success", statementData.id);
          console.log("res", res);
          setIsSuccessful(true);
        } catch(error) {
          setIsFailure(true);
        } finally {
          setLoading(false);
        }
      } else {
        setIsFailure(true);
      }
      setIsPaymentDone(true);
      setIsOpen(false);
      setIsPaying(false);
    };

    payabliConfigACH.functionCallBackSuccess = async (response: any) => {
      setIsOpen(false);
      if(response && response.isSuccess) {
        try {
          setLoading(true);
          // @ts-ignore
          const res = await postPaymentForLoginPayabli(response?.responseData?.referenceId, "Success", statementData.id);
          setIsSuccessful(true);
        } catch(error) {
          setIsFailure(true);
        } finally {
          setLoading(false);
        }
      } else {
        setIsFailure(true);
      }
      setIsPaymentDone(true);
      setIsOpen(false);
      setIsPaying(false);
    };
  
    Object.assign(payabliConfigCard.customerData, {
      firstName: statementData?.firstName,
      lastName: statementData?.lastName,
      customerNumber: "",
      billingEmail: "",
      additionalData: {
        payLinkById: statementData?.id,
      },
    });

    Object.assign(payabliConfigACH.customerData, {
      firstName: statementData?.firstName,
      lastName: statementData?.lastName,
      customerNumber: "",
      billingEmail: "",
      additionalData: {
        payLinkById: statementData?.id,
      },
    });

    // Ensure the component is reinitialized if the reference is null
    if (!payabliComponentRef?.current) {
      // @ts-ignore
      payabliComponentRef.current = new PayabliComponent(payabliConfigCard);
    } else {
      // @ts-ignore
      payabliComponentRef.current.updateConfig(payabliConfigCard);
    }
    const payButton = document.getElementById("btn-pay");
    if (payButton && !payButton.dataset.listenerAdded) {
      payButton.dataset.listenerAdded = "true";
      payButton.addEventListener("click", handlePayClick);
    }
  };
  

  const handlePayClick = () => {
    if (isPaying) return;
    setIsPaying(true);
  
    // Ensure payabliComponentRef.current exists before using it
    if (payabliComponentRef.current) {
      // @ts-ignore
      payabliComponentRef.current.payabliExec("pay", {
        paymentDetails: {
          totalAmount: statementData?.amountDue || 0,
          serviceFee: 0,
          categories: [{ label: "payment", amount: statementData?.amountDue || 0, qty: 1 }],
        },
      });
    } else {
      console.error("PayabliComponent is not initialized.");
      setIsPaying(false);
    }
  };

  const closePopup = () => {
    setIsOpen(false);
    payabliComponentRef.current = null; // Reset the ref when closing
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="min-h-screen bg-white h-full">
      {/* Header */}
      <div className="bg-purple-900 p-4 md:p-6">
        {loading && (<LoadingOverlay />)}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center text-white mb-4 md:mb-8 gap-4">
          <div className="flex items-center gap-2">
            <button 
              onClick={handleBackClick}
              className="hover:bg-purple-800 p-2 rounded-full"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <span className="text-sm md:text-base">My Bills / Bill Summary</span>
          </div>
          {/* <button className="flex items-center justify-center gap-2 bg-white text-purple-900 px-4 py-2 rounded-full text-sm md:text-base">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            Download Summary
          </button> */}
        </div>

        <div className="text-white mb-4">
          <h1 className="text-2xl md:text-4xl font-semibold mb-2">Statement #{statementData.statementNumber}</h1>
          <h2 className="text-lg md:text-xl">{statementData.provider}</h2>
        </div>
      </div>

        

      {/* Amount Due Card */}
      <div className='bg-[#F3F2F9] w-[100%] min-h-[calc(100vh-160px)] relative'>
        <div className="flex justify-between items-center bg-white rounded-full shadow-md py-3 md:py-4 px-4 md:px-8 mx-4 md:mx-auto max-w-md absolute top-[-50px] left-0 right-0">
          <span className="text-gray-700 text-base md:text-lg">Amount Due</span>
          <span className="text-purple-900 text-2xl md:text-3xl font-semibold">${statementData.amountDue.toFixed(2)}</span>
        </div>

        <div className='pt-16 pb-8 px-4'>
            <div className="bg-white mx-auto max-w-3xl rounded-3xl shadow-lg">
                {/* Statement Details */}
                <div className="p-4 md:p-8">
                <div className="space-y-6">
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Patient Name</span>
                    <span className="text-gray-900">{statementData.firstName + " " + statementData.lastName}</span>
                    </div>
                    
                    {/* <div className="flex justify-between items-center">
                    <span className="text-gray-500">Patient Account Number</span>
                    <span className="text-gray-900">{statementData.patient.accountNumber}</span>
                    </div> */}
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Patient Address</span>
                    {/* <span className="text-gray-900 text-right">
                        {statementData.patient.address},<br />
                        {statementData.patient.city}, {statementData.patient.state} {statementData.patient.zipCode}
                    </span> */}
                    <span className="text-gray-900 text-right">
                        {statementData.patientAddress}
                    </span>
                    </div>

                    <div className="border-b border-dashed border-gray-200" />
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Statement Date</span>
                    <span className="text-gray-900">{statementData.dates.statement}</span>
                    </div>
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Due Date</span>
                    <span className="text-purple-900 font-semibold">{statementData.dates.due}</span>
                    </div>

                    <div className="border-b border-dashed border-gray-200" />
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Total Charges</span>
                    <span className="text-gray-900">{statementData.charges.total}</span>
                    </div>
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Adjustments</span>
                    <span className="text-gray-900">{statementData.charges.adjustments}</span>
                    </div>
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Insurance Payments</span>
                    <span className="text-gray-900">{statementData.charges.insurancePayments}</span>
                    </div>
                    
                    <div className="flex justify-between items-center">
                    <span className="text-gray-500">Patient Payments</span>
                    <span className="text-gray-900">{statementData.charges.patientPayments}</span>
                    </div>
                </div>
                </div>

                {/* Pay Bill Button */}
                <div className="p-4 md:p-8">
                <button 
                  onClick={loadPayabliScript}
                  className={`w-full bg-coral-500 hover:bg-coral-600 text-white py-3 md:py-4 rounded-lg text-base md:text-lg ${theme['button-bg']}`}
                >
                    Pay Bill
                </button>
                </div>
            </div>
        </div>
      </div>

      {/* Ask Sandra Button */}
      {/* <div className="fixed bottom-4 right-4">
        <button className="bg-white rounded-full px-4 md:px-6 py-2 md:py-3 flex items-center gap-2 shadow-lg">
          <div className="w-6 h-6 md:w-8 md:h-8 bg-purple-200 rounded-full"></div>
          <span className="text-purple-900 font-semibold text-sm md:text-base">Ask Sandra</span>
          <svg className="w-4 h-4 md:w-5 md:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
      </div> */}

      {/* Payabli Payment Modal */}
      {/* <PayabaliPopUp
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setIsPaymentModalOpen(false);
        }}
        payabliComponentRef={payabliComponentRef}
        isCardTab={isCardTab}
        setIsCardTab={setIsCardTab}
        handlePayClick={handlePayClick}
        isPaying={isPaying}
      /> */}

        {/* Popup */}
        <div className={`fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 ${isOpen ? '' : 'hidden'}`}>
            <div className="w-11/12 max-w-sm sm:max-w-md mx-auto border-2 rounded-lg p-4 sm:p-6 bg-white shadow-lg relative">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-lg sm:text-xl"
                onClick={closePopup}
              >
                ✕
              </button>
              <h4 className="text-base sm:text-lg font-bold text-gray-700 mb-4 text-center">Payment Method</h4>

              {/* Tabs */}
              <div className="tabs mb-4 flex border-b">
                <button
                  className={`flex-1 py-2 text-center text-sm sm:text-base font-bold ${
                    isCardTab ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
                  }`}
                  onClick={() => setIsCardTab(true)}
                >
                  Payment Card
                </button>
                <button
                  className={`flex-1 py-2 text-center text-sm sm:text-base font-bold ${
                    !isCardTab ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
                  }`}
                  onClick={() => setIsCardTab(false)}
                >
                  Bank Debit
                </button>
              </div>

              {/* Tab Content */}
              <div className="payment-form">
                <div id="pay-component-card"></div>

                {/* Pay Button */}
                <button
                  id="btn-pay"
                  className="w-full py-3 bg-gradient-to-r from-purple-600 to-blue-400 text-white font-bold text-sm sm:text-base uppercase rounded-md flex items-center justify-center mt-4"
                >
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAPxJREFUSEvtldGNwjAMhr9/AnQTAJNwbMAG9CZATAC3ARvABjACN8mVDW4DI6Mg9VqStEWVeMAvjSrb3x8ndsTApoHz0wpgZgtgBXwGQWfgW5J/k5YFmNkBWEayOGSbIiQBQfkxJFgDp7AugE1Yz1M7yQG8BDNgLWlXVWpmrtwhP5LupWtsJgewEPEh6a8GmAC//k9SNE8rQCyBmd0EDAbI3aAbPOXURmEO0gCYmR/YHvAad7ES+KrfqEcAdxx3yVzxLSVNq7GPAPeb04tRP/A3oNm5taZ7l+g1S+RTc9SrCeAi6d8EiI0Kf8W6dvMFKLKjoqfyaFj2TX4WeAX0MmsZ8nx06AAAAABJRU5ErkJggg=="
                    alt="Save Payment Icon"
                    className="mr-2 w-4 h-4 sm:w-5 sm:h-5"
                  />
                  Pay
                </button>
              </div>
            </div>
          </div>
      {/* Success Message */}
      {isSuccessful && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-xl font-semibold text-green-600 mb-2">Payment Successful!</h3>
            <p className="text-gray-600 mb-4">Your payment has been processed successfully.</p>
            <button 
              onClick={() => {setIsSuccessful(false);
                window.location.href ="/bills";
              }}
              className="w-full px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Error Message */}
      {isFailure && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-xl font-semibold text-red-600 mb-2">Payment Failed</h3>
            <p className="text-gray-600 mb-4">There was an error processing your payment. Please try again or contact support.</p>
            <button 
              onClick={() => setIsFailure(false)}
              className="w-full px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

