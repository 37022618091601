import React, { useRef, useEffect, useState } from 'react';
import ChatHeader from './ChatHeader';
import MessageInput from './MessageInput';
import DynamicResponseRenderer from './DynamicResponseRenderer';
import { ChatProvider, useChatContext } from '../../context/ChatContext';
import ComponentRegistry from './ComponentRegistry';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import MessageWithLogin from '../home/MessageWithLogin';
import { throttle } from '../../utils/utils';
import { useTheme } from '../../context/ThemeContext';
import { v4 as uuidv4 } from 'uuid';
import LoadingOverlay from '../insurance/LoadingOverlay';

const ChatInterface: React.FC = () => {
  const {
    displayedMessages,
    sendMessageWithResponse,
    shouldComponentRender,
    componentName,
    componentMessage,
    isChatLoading,
    setNewMessage,
  } = useChatContext();
  const { theme, isSetupComplete } = useTheme();
  const { isAuthenticated } = useAuthContext();
  const [currentText, setCurrentText] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [isTypingCompleted, setIsTypingCompleted] = useState<boolean>(false);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const isTablet = window.innerWidth <= 1024;

  useEffect(() => {
    if(isSetupComplete) {
      const { question, answer } = location.state || {};
      const session = uuidv4();
      setSessionId(session);
      if (!isAuthenticated && question && answer) {
        setNewMessage({
          id: Date.now().toString(),
          type: 'text',
          content: question,
          sender: 'user',
        });
        setNewMessage({
          id: Date.now().toString(),
          type: 'text',
          content: answer,
          sender: 'assistant',
        });
      } else if (isAuthenticated && question) {
        sendMessageWithResponse(question, theme.client, session);
      } else {
        setNewMessage({
          id: Date.now().toString(),
          type: 'text',
          content:
            theme.sandraMessage,
          sender: 'assistant',
        });
      }
    }
  }, [isSetupComplete]);

  const chatScrollUp = throttle(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, 750);

  useEffect(() => {
    const timeout = setTimeout(() => {
      chatScrollUp();
    }, 0);

    return () => clearTimeout(timeout);
  }, [displayedMessages]);

  const handleUserMessage = (message: string) => {
    sendMessageWithResponse(message, theme.client, sessionId);
    setCurrentText('');
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = '';
    };
  }, []);

  const renderComponent =
    componentName && shouldComponentRender && componentMessage;
  const Component = renderComponent ? ComponentRegistry[componentName] : null;

  return (
    <div
      className="flex flex-col relative md:pr-2 overflow-hidden md:overflow-auto h-screen"
      style={isTablet ? { height: 'calc(100vh - 64px)' } : {}}
    >
      {!isSetupComplete && <LoadingOverlay />}
      <ChatHeader />
      <div
        className={
          'flex-1 px-4 pt-4 bg-gray-10 overflow-x-hidden overflow-y-auto'
        }
      >
        {displayedMessages.map(
          (
            msg: any,
            index: { toString: () => React.Key | null | undefined }
          ) => (
            <DynamicResponseRenderer
              key={index.toString()}
              message={msg}
              chatScrollUp={chatScrollUp}
              setIsTypingCompleted={setIsTypingCompleted}
              isTypingCompleted={isTypingCompleted}
              sessionId={sessionId}
            />
          )
        )}
        {isChatLoading && (
          <DynamicResponseRenderer
            message={null}
            isChatLoading
            chatScrollUp={chatScrollUp}
            sessionId={sessionId}
          />
        )}
        <div ref={chatEndRef} />
        {renderComponent && Component ? (
          <Component message={componentMessage} />
        ) : null}
        {!isAuthenticated && isTypingCompleted && (
          <div className="flex items-center justify-center">
            {' '}
            <MessageWithLogin />{' '}
          </div>
        )}
      </div>
      {isAuthenticated && (
        <>
          {' '}
          <div style={{ height: '125px' }} className={'bg-gray-10'}/>{' '}
          <MessageInput
            text={currentText}
            setText={setCurrentText}
            onSendMessage={handleUserMessage}
            sessionId={sessionId}
          />
        </>
      )}
    </div>
  );
};

const ChatInterfaceWithProvider: React.FC = () => (
  <ChatProvider>
    <ChatInterface />
  </ChatProvider>
);

export default ChatInterfaceWithProvider;
