import React, { useEffect, useState, useRef, useCallback } from "react";
import LinkPaymentSideImage from "../../assets/SVGs/LinkPaymentSideImage";
import { useTheme } from "../../context/ThemeContext";
import { getPaymentDetails , updatePostPayment, isStageOrDevEnv, getPayabliSrc} from "../../utils/utils";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../insurance/LoadingOverlay";
import { payabliConfigACH, payabliConfigCard } from "./Payabli";
import { useDispatch, useSelector } from "react-redux";
import { hideSideBar, openSideBar } from "../../store/sidebarSlice";
import { RootState } from "../../store";

const LinkPayment: React.FC = () => {
  
  const { theme } = useTheme();
  const [paymentData, setPaymentData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const payabliComponentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isCardTab, setIsCardTab] = useState(true);
  const [isPaying, setIsPaying] = useState(false); // Prevent multiple clicks
  const dispatch = useDispatch()
  const isOpenSidbar = useSelector((state: RootState) => state.sidebar.isOpen);

  const closePopup = () => {
    setIsOpen(false);
    payabliComponentRef.current = null; // Reset the ref when closing
  };
  

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if(isOpenSidbar) dispatch(hideSideBar())
  }, [isOpenSidbar])

  useEffect(() => {
    if (!id) return;
    const fetchPaymentData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await getPaymentDetails(id);
        setPaymentData(response);
        if(response?.status?.toLowerCase() === "paid") {
          setIsPaymentDone(true);
          setIsSuccessful(true);
        }
      } catch (err: any) {
        setError(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentData();
  }, [id]);

  useEffect(() => {
    if (!payabliComponentRef?.current) return;

    if (isCardTab) {
      // @ts-ignore
      payabliComponentRef.current.updateConfig(payabliConfigCard);
    } else {
      // @ts-ignore
      payabliComponentRef.current.updateConfig(payabliConfigACH);
    }
  }, [isCardTab]);

  const loadPayabliScript = () => {
    if (!document.querySelector('script[src*="payabli.com/component.js"]')) {
      const script = document.createElement("script");
      script.src = getPayabliSrc();
      if(isStageOrDevEnv()) {
        script.dataset.test = "true";
      }
      script.onload = initializePayabliForm;
      document.body.appendChild(script);
    } else {
      initializePayabliForm();
    }
    setIsOpen(true);
  };

  const initializePayabliForm = () => {
    // @ts-ignore
    if (typeof PayabliComponent === "undefined") {
      console.error("Payabli script not loaded.");
      return;
    }
    // @ts-ignore
    payabliConfigCard.token = paymentData?.payabliToken;
    // @ts-ignore
    payabliConfigCard.entryPoint = paymentData?.payabliEntryId;

    // @ts-ignore
    payabliConfigACH.token = paymentData?.payabliToken;
    // @ts-ignore
    payabliConfigACH.entryPoint = paymentData?.payabliEntryId;


    payabliConfigCard.functionCallBackSuccess = async (response: any) => {
      if(response && response.isSuccess) {
        try {
          // @ts-ignore
          await updatePostPayment(response?.responseData?.referenceId, id);
          setIsSuccessful(true);
        } catch(error) {
          setIsFailure(true);
        }
      } else {
        setIsFailure(true);
      }
      setIsPaymentDone(true);
      setIsOpen(false);
      setIsPaying(false);
    };

    payabliConfigACH.functionCallBackSuccess = async (response: any) => {
      if(response && response.isSuccess) {
        try {
          // @ts-ignore
          await updatePostPayment(response?.responseData?.referenceId, id);
          setIsSuccessful(true);
        } catch(error) {
          setIsFailure(true);
        }
      } else {
        setIsFailure(true);
      }
      setIsPaymentDone(true);
      setIsOpen(false);
      setIsPaying(false);
    };
  
    Object.assign(payabliConfigCard.customerData, {
      firstName: paymentData?.firstName,
      lastName: paymentData?.lastName,
      customerNumber: paymentData?.invoiceNumber,
      billingEmail: paymentData?.email,
      additionalData: {
        payLinkById: id,
      },
    });

    Object.assign(payabliConfigACH.customerData, {
      firstName: paymentData?.firstName,
      lastName: paymentData?.lastName,
      customerNumber: paymentData?.invoiceNumber,
      billingEmail: paymentData?.email,
      additionalData: {
        payLinkById: id,
      },
    });

    // Ensure the component is reinitialized if the reference is null
    if (!payabliComponentRef?.current) {
      // @ts-ignore
      payabliComponentRef.current = new PayabliComponent(payabliConfigCard);
    } else {
      // @ts-ignore
      payabliComponentRef.current.updateConfig(payabliConfigCard);
    }
    const payButton = document.getElementById("btn-pay");
    if (payButton && !payButton.dataset.listenerAdded) {
      payButton.dataset.listenerAdded = "true";
      payButton.addEventListener("click", handlePayClick);
    }
  };
  

  const handlePayClick = () => {
    if (isPaying) return;
    setIsPaying(true);
  
    // Ensure payabliComponentRef.current exists before using it
    if (payabliComponentRef.current) {
      // @ts-ignore
      payabliComponentRef.current.payabliExec("pay", {
        paymentDetails: {
          totalAmount: paymentData?.amount || 0,
          serviceFee: 0,
          categories: [{ label: "payment", amount: paymentData?.amount || 0, qty: 1 }],
        },
      });
    } else {
      console.error("PayabliComponent is not initialized.");
      setIsPaying(false);
    }
  };
  

  if (loading) return <LoadingOverlay />;

  return (
    <div className="flex flex-col md:flex-row items-center justify-center bg-[#F3F2F9] min-h-screen p-8 overflow-hidden">
      {/* Left Section */}
      { isPaymentDone ? (
        isSuccessful ? (
          <div className="text-center">
            <h1 className="text-3xl font-bold text-green-600 mb-4">Thank You!</h1>
            <p className="text-lg text-gray-700">Your payment was successfully processed.</p>
          </div>
        ) : (
          isFailure && ( 
            <div className="text-center">
              <h1 className="text-3xl font-bold text-red-600 mb-4">Payment Failed</h1>
              <p className="text-lg text-gray-700">Unfortunately, your payment could not be processed. Please try again or contact support for assistance.</p>
            </div>
          )
        )
      ) : (
        <div className="w-full md:w-1/2 max-w-[484px] ml-5">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-80 mb-6">Pay Bill</h1>

          {/* Total Amount Due */}
          <div className="mb-5">
            <label className="block text-gray-80 text-sm font-bold mb-3">Pay Total Amount Due</label>
            <label className="block text-gray-50 text-xs font-medium mb-3">
              Or adjust the amount below to make a partial payment
            </label>
            <div className="bg-white rounded-lg px-4 py-3 text-gray-80 text-lg font-bold mb-2">
              ${paymentData?.amount || "0"}
            </div>
          </div>

          {/* Payment Details */}
          <div className="mb-5">
            <label className="block text-gray-800 text-sm font-bold mb-3">Bill Details</label>
            <div className="rounded-lg pb-2">
              {[
                ["Invoice Number", paymentData?.invoiceNumber],
                ["Provider", paymentData?.providerName],
                ["Due Date", paymentData?.dueDate?.join("-")],
                ["Name", `${paymentData?.firstName || ""} ${paymentData?.lastName || ""}`],
                ["Email", paymentData?.email],
                ["Phone", paymentData?.phoneNumber],
              ].map(([label, value]) => (
                <div key={label} className="flex text-sm font-semibold mb-2">
                  <span className="w-32 text-gray-800">{label}:</span>
                  <span className="text-gray-500">{value || "N/A"}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Pay Now Button */}
          <button
            onClick={loadPayabliScript}
            className={`w-full bg-[#F6643D] text-white text-lg font-semibold rounded-lg px-6 py-3 ${theme["home-button-text"]}`}
          >
            Pay ${paymentData?.amount || "0"}
          </button>

          {/* Popup */}
          <div className={`fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 ${isOpen ? '' : 'hidden'}`}>
            <div className="w-11/12 max-w-sm sm:max-w-md mx-auto border-2 rounded-lg p-4 sm:p-6 bg-white shadow-lg relative">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-lg sm:text-xl"
                onClick={closePopup}
              >
                ✕
              </button>
              <h4 className="text-base sm:text-lg font-bold text-gray-700 mb-4 text-center">Payment Method</h4>

              {/* Tabs */}
              <div className="tabs mb-4 flex border-b">
                <button
                  className={`flex-1 py-2 text-center text-sm sm:text-base font-bold ${
                    isCardTab ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
                  }`}
                  onClick={() => setIsCardTab(true)}
                >
                  Payment Card
                </button>
                <button
                  className={`flex-1 py-2 text-center text-sm sm:text-base font-bold ${
                    !isCardTab ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
                  }`}
                  onClick={() => setIsCardTab(false)}
                >
                  Bank Debit
                </button>
              </div>

              {/* Tab Content */}
              <div className="payment-form">
                <div id="pay-component-card"></div>

                {/* Pay Button */}
                <button
                  id="btn-pay"
                  className="w-full py-3 bg-gradient-to-r from-purple-600 to-blue-400 text-white font-bold text-sm sm:text-base uppercase rounded-md flex items-center justify-center mt-4"
                >
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAPxJREFUSEvtldGNwjAMhr9/AnQTAJNwbMAG9CZATAC3ARvABjACN8mVDW4DI6Mg9VqStEWVeMAvjSrb3x8ndsTApoHz0wpgZgtgBXwGQWfgW5J/k5YFmNkBWEayOGSbIiQBQfkxJFgDp7AugE1Yz1M7yQG8BDNgLWlXVWpmrtwhP5LupWtsJgewEPEh6a8GmAC//k9SNE8rQCyBmd0EDAbI3aAbPOXURmEO0gCYmR/YHvAad7ES+KrfqEcAdxx3yVzxLSVNq7GPAPeb04tRP/A3oNm5taZ7l+g1S+RTc9SrCeAi6d8EiI0Kf8W6dvMFKLKjoqfyaFj2TX4WeAX0MmsZ8nx06AAAAABJRU5ErkJggg=="
                    alt="Save Payment Icon"
                    className="mr-2 w-4 h-4 sm:w-5 sm:h-5"
                  />
                  Pay
                </button>
              </div>
            </div>
          </div>

        </div>
      )}

      {/* Right Section */}
      <div className="hidden md:flex w-full md:w-1/2 items-center justify-center mt-10 md:mt-0">
        <LinkPaymentSideImage />
      </div>
    </div>
  );
};

export default LinkPayment;
