import { ISidebarData } from '../utils/types';
import { getFeaturesForCustomer, Features } from '../utils/ClientBasedFeature';
import { isOvum } from '../utils/utils';

export const sidebarBodyIcons: Array<ISidebarData> = [
  {
    id: 1,
    iconName: 'Home',
    title: 'Home',
    notification: '',
    path: '/',
    isActive: true,
    isHidden: false
  },
  {
    id: 2,
    iconName: 'Sandra',
    title: 'Ask Sandra',
    notification: '',
    path: '/asksandra',
    isActive: true,
    isHidden: false
  },
  {
    id: 3,
    iconName: 'Bill',
    title: 'Bills & Estimates',
    notification: '',
    path: '/bills',
    isActive: true,
    isHidden: !(getFeaturesForCustomer().showBillPaymentOption)
  },
  {
    id: 4,
    iconName: 'Dollar',
    title: 'SandraVoice',
    notification: '',
    path: '/Sandravoice',
    isActive: true,
    isHidden: !(getFeaturesForCustomer().sandraVoice)
  },
  {
    id: 6,
    iconName: 'Insurance',
    title: 'Insurance',
    notification: '',
    path: '/insurance',
    isActive: true,
    isHidden: false
  },
  {
    id: 7,
    iconName: 'Gear',
    title: 'Setting',
    notification: '',
    path: '/setting',
    isActive: true,
    isHidden: false
  },
];

// Filter out "Home" if `isOvum` is true
export const filteredSidebarBodyIcons = isOvum()
  ? sidebarBodyIcons.filter(icon =>  !(['Home', 'Setting', 'SandraVoice', 'Insurance'].includes(icon.title)))
  : sidebarBodyIcons;

export const sidebarFootericons = [
  {
    id: 8,
    iconName: 'SignOut',
    title: 'Log Out',
    notification: '',
    path: '',
  },
];
