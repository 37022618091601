import axios from 'axios';
import { getDomain } from './constants';

export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    let cookie = cookieArr[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

// setCookie.js
export const setCookie = (
  value: string,
  name = 'Authorized',
  days = 1,
  secure = false,
  sameSite = 'Lax'
) => {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
  const date = new Date();
  date.setDate(date.getDate() + days);
  cookieString += `expires=${date.toUTCString()};`;
  if (secure) {
    cookieString += 'Secure;';
  }
  cookieString += `SameSite=${sameSite};`;
  cookieString += 'path=/;';
  document.cookie = cookieString;
};

export const loginApi = async (username: string, password: string) => {
  try {
    const response = await axios.post(getDomain() + '/auth/login', {
      username: username,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching total collection:', error);
    throw error;
  }
};

export type SignUpFormType = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
};

export const isValidSignUpFormData = (form: SignUpFormType) => {
  if (!form.firstName || !(form.firstName?.length > 2)) {
    return 'Please enter first name';
  }
  if (!form.lastName || !(form.lastName?.length > 2)) {
    return 'Please enter first name';
  }
  if (!form.userName || !(form.userName?.length > 2)) {
    return 'Please enter username';
  }
  if (!form.email || !form.email.includes('@')) {
    return 'Please enter valid email';
  }
  if (!form.password || !(form.password?.length > 8)) {
    return 'Please enter a password with at least 8 characters';
  }
  if (form.password !== form.confirmPassword) {
    return 'Passwords do not match';
  }
  if (!form.phoneNumber || !form.phoneNumber?.match(/^[0-9]{10}$/)) {
    return 'Please enter a valid phone number';
  }
  return null;
};

export const signUpApi = async (form: SignUpFormType) => {
  try {
    const response = await axios.post(getDomain() + '/auth/signup', {
      firstName: form.firstName,
      lastName: form.lastName,
      username: form.userName,
      email: form.email,
      password: form.password,
      phone: form.phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export type UserEligibilityType = {
  membershipId: string;
  insuranceCompanyName: string;
  primaryInsuranceSubscriber: boolean;
  dateOfBirth: string;
};

export const postUserEligibilityData = async (form: UserEligibilityType) => {
  const token: any = await localStorage.getItem('idToken');
  try {
    const response = await axios.post(
      getDomain() + '/user_eligibility_data/create',
      {
        membershipId: form.membershipId,
        insuranceCompanyName: form.insuranceCompanyName,
        primaryInsuranceSubscriber: form.primaryInsuranceSubscriber,
        userDob: form.dateOfBirth,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in post request of user eligibility api up:', error);
    throw error;
  }
};

export const getPayers = async () => {
  try {
    const response = await axios.get( getDomain() + '/payers', {});
    return response.data;
  } catch (error) {
    console.error('Error fetching payers:', error);
    throw error;
  }
};
export const confirmSignUpOtp = async (username: string, otp: string) => {
  try {
    const response = await axios.post(
      getDomain() + `/auth/confirm?username=${username}&confirmationCode=${otp}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const getPaymentDetails = async (id: string) => {
  try {
    const response = await axios.get( getDomain() + '/public/api/payments/' + id);
    return response.data;
  } catch (error) {
    console.error('Error fetching payers:', error);
    throw error;
  }
}

export const updatePostPayment = async (referenceId: string, id: string) => {
  try {
    const response = await axios.post( getDomain() + '/public/api/payments/post-payment', {
      "paymentType": "payabli",
      "paymentId": id,
      "paymentReferenceId": referenceId
    });
    return response.data;
  } catch (error) {
    console.error('Error update payment status:', error);
    throw error;
  }
}

export const hideSidenav = () => {
  if (window.location.pathname === '/login') {
    return false;
  }
  if(isOvum() && window.location.pathname === '/') {
    return false;
  }

  return true;
};

export const getTimeDifference = (date: Date | number): boolean => {
  const inputTime = typeof date === 'number' ? date : date.getTime();
  const differenceInMilliseconds = Math.abs(inputTime - Date.now());
  return differenceInMilliseconds >= 60000;
};

export const debounce = (func: Function, delay: number) => {
  let timer: any;
  // @ts-ignore
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, delay);
  };
};

export const goBack = () => {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    window.location.href = '/';
  }
};

export function throttle(func: Function, limit: number) {
  let lastFunc: NodeJS.Timeout;
  let lastRan: number;

  return function (this: any, ...args: any[]) {
    const context = this;
    const now = Date.now();

    if (!lastRan) {
      func.apply(context, args);
      lastRan = now;
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        () => {
          if (now - lastRan >= limit) {
            func.apply(context, args);
            lastRan = now;
          }
        },
        limit - (now - lastRan)
      );
    }
  };
}

export const logout = () => {
  document.cookie =
    'authorized=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  localStorage.setItem('isAuthenticated', 'false');
  localStorage.setItem('idToken', '');
  localStorage.setItem('username', '');
  localStorage.setItem('firstName', '');
  localStorage.setItem('lastName', '');
  localStorage.setItem('email', '');
  localStorage.setItem('phone', '');
  localStorage.setItem('userid', '');
  localStorage.setItem('authTimestamp', new Date().getTime() + '');
  window.location.href = '/';
};

export const AddNewUserSessionId = async (username: string, sessionId: string) => {
  const token: any = await localStorage.getItem('idToken');
  const userId: any = await localStorage.getItem('userid');
  try {
    const response = await axios.post(
      getDomain() + '/api/sessions',
      {
        userId: userId,
        sessionId: sessionId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in post request of Add New User Service api up:', error);
    throw error;
  }
}

export const changePasswordApi = async (oldPassword: string, newPassword: string) => {
  const token: any = getCookie('Authorized');
  const username = await localStorage.getItem('username');
  try {
    const response = await axios.post(
      getDomain() + '/auth/changepassword',
      {
        accessToken: token,
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in post request of change password api up:', error);
    return null;
  }
}

export const isOvum = () => {
  return window.location.href.includes('ovum');
}

export const isStageOrDevEnv = () => {
  return (window.location.href.includes('stage') || window.location.href.includes('localhost') || window.location.href.includes('dev'));
}

export const getPayabliSrc = () => {
  if(isStageOrDevEnv()) {
    return "https://embedded-component-sandbox.payabli.com/component.js"
  } else {
    return "https://embedded-component.payabli.com/component.js"
  }
}
export const formatDateToYYYYMMDD = (date: any) => {
  const [year, month, day] = date.split('-');
  return `${year}-${month}-${day}`;
};

export const sendInsuranceData = async (formData: any) => {
  try {
    const response = await axios.post(
      getDomain() + '/auth/ovum/user',
      {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        email: formData.email,
        membershipId: formData.insuranceMemberId,
        insuranceCompanyName: formData.insuranceCompany,
        insuranceExist: !!formData.isInsuranceExist,
        userDob: formData.dob && formatDateToYYYYMMDD(formData.dob),
      }
    );
    localStorageUpdateAfterLogin(response?.data);
    return response;
  } catch (error) {
    console.error('Error in post request of change password api up:', error);
    return null;
  }
}

export const authenticatedAxios = async (url: string, options?: any): Promise<any> => {
  const token: any = await localStorage.getItem('idToken');

  const defaultHeaders: any = {
    'Content-Type': 'application/json',
  };

  if (token) {
    defaultHeaders['Authorization'] = `Bearer ${token}`;
  }

  const axiosOptions: any = {
    method: options?.method || 'GET',
    url,
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options?.headers || {}),
    },
    data: options?.body,
    params: options?.params,
  };
  
  return axios(axiosOptions);
};

function convertDateFormat(dateString: string) {
  try {
    const date = new Date(dateString);
    const options: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  } catch (err: any){
    return dateString;
  }
}

export const fetchBillsByStatus = async (status?: string) => {
  try {
    let response;
    if(!status) {
      response = await authenticatedAxios(`${getDomain()}/api/receipts`);
    } else {
      response = await authenticatedAxios(`${getDomain()}/api/receipts?status=${status}`);
    }
    
    // const receiptsByUser = response.data?.receiptsByUser || [];
    const receiptsByProvider = response.data?.receiptsByProvider || [];
    // const receiptsBySchedulePayment = response.data?.receiptsBySchedulePayment || [];

    // const receiptsByUserWithType = receiptsByUser.map((receipt : any) => {
    //   const newReceipt = receipt;
    //   newReceipt['type'] = 'Ocr';
    //   newReceipt['statementDate'] = convertDateFormat(newReceipt['statementDate']);
    //   newReceipt['dueDateUnformatted'] = newReceipt['dueDate'];
    //   newReceipt['dueDate'] = convertDateFormat(newReceipt['dueDate']);
    //   return newReceipt;
    // });
    const receiptsByProviderWithType = receiptsByProvider.map((receiptsByProvider : any) => {
      const newReceipt = receiptsByProvider;
      newReceipt['type'] = 'Provider';
      newReceipt['statementDate'] = convertDateFormat(newReceipt['statementDate']);
      newReceipt['dueDateUnformatted'] = newReceipt['dueDate'];
      newReceipt['dueDate'] = convertDateFormat(newReceipt['dueDate']);
      if (status) {
        newReceipt['paymentDate'] = convertDateFormat(newReceipt['updatedAt']);
        newReceipt['isPaid'] = true;
      } else {
        newReceipt['isPaid'] = false;
      }
      return newReceipt;
    });
    // const receiptsByScheduledWithType = receiptsBySchedulePayment.map((receipts : any) => {
    //   const newReceipt = receipts;
    //   newReceipt['type'] = 'Scheduled';
    //   newReceipt['statementDate'] = convertDateFormat(newReceipt['statementDate']);
    //   newReceipt['dueDateUnformatted'] = newReceipt['dueDate'];
    //   newReceipt['dueDate'] = convertDateFormat(newReceipt['dueDate']);
    //   return newReceipt;
    // });
    return { receiptsByProvider:  receiptsByProviderWithType };
  } catch (error) {
    console.error('Error:', error);
  }
}
export const localStorageUpdateAfterLogin = (response: any) => {
  if(response) {
    const currentTime = new Date().getTime();
    setCookie(response?.data.accessToken);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('idToken', response.data.idToken);
    localStorage.setItem('username', response.data.user.username);
    localStorage.setItem('userid', response.data.user.id);
    localStorage.setItem('authTimestamp', currentTime.toString());
    localStorage.setItem('firstName', response.data.user.firstName);
    localStorage.setItem('lastName', response.data.user.lastName);
    localStorage.setItem('email', response.data.user.email);
    localStorage.setItem('phone', response.data.user.phone);
  }
}

// Function to calculate a score based on word matches
const getMatchScore = (option: string, searchTerms: string[]): number => {
  const lowerOption = option.toLowerCase();
  let score = 0;

  searchTerms.forEach(term => {
    if (lowerOption.includes(term)) {
      score += 1; // Increase score for each term found
      if (lowerOption.startsWith(term)) {
        score += 1; // Extra point if the term is a prefix
      }
    }
  });

  return score;
};

// Enhanced search function to support multi-word search terms
export const smartSearch = (options: string[], searchTerm: string): string[] => {
  const searchTerms = searchTerm.toLowerCase().split(/\s+/); // Split search terms by spaces

  return options
    .map(option => ({ 
      option, 
      score: getMatchScore(option, searchTerms) 
    }))
    .filter(({ score }) => score > 0) // Keep only matched options
    .sort((a, b) => b.score - a.score) // Sort by score, highest first
    .map(({ option }) => option); // Return only the options
};

export const getPayabliTokens = async (providerId: string) => {
  const token: any = await localStorage.getItem('idToken');
  try {
    const response = await axios.get(
      getDomain() + '/api/get-payabli-tokens/provider-id/' + providerId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in post request of user eligibility api up:', error);
    throw error;
  }
};

export const postPaymentForLoginPayabli = async (paymentId: string, status: string, receiptId: string) => {
  try {
    const token: any = await localStorage.getItem('idToken');
    const response = await axios.post(
      getDomain() + '/api/post-payment',
      {
        paymentAggregator: "payabli",
        paymentIdByAggregator: paymentId,
        receiptType: "provider",
        status: status,
        receiptId: receiptId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error in post request of change password api up:', error);
    return null;
  }
}