import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Sidebar from './components/sidebar/Sidebar';
import AppRoutes from './router';
import { hideSidenav } from './utils/utils';
import MobileHeader from './components/sidebar/MobileHeader';
import { useAuthContext } from './context/AuthContext';
import { useTheme } from './context/ThemeContext';

const queryClient = new QueryClient();

function App() {
  const { theme } = useTheme();
  const { isAuthenticated } = useAuthContext();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <MobileHeader />
        <div className="App flex w-full h-auto">
          {hideSidenav() && isAuthenticated && <Sidebar />}
          <div className={`w-full ${theme['bg-default']}`}>
            <AppRoutes />
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
